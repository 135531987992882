import classNames from "classnames";
import { TodoType } from "types/db/todos";

import { displayNameFromContact } from "@lib/contacts";
import { AccountType } from "@lib/data/schemas/account";
import { getHumanizedDate, momentDate } from "@lib/utils/todos";

import CheckIcon from "@components/Icons/CheckIcon";

import { todoTextColor } from "./TodoListItem";

interface TodoDoneProps {
  todo: TodoType;
  membersAndContact: AccountType[];
  color: keyof typeof todoTextColor;
  messaging?: boolean;
}

const TodoDone: React.FC<TodoDoneProps> = ({
  todo,
  membersAndContact,
  color,
  messaging,
}) => {
  const { markedBy, updatedAt } = todo;

  const markedCompleteBy =
    markedBy &&
    displayNameFromContact(
      membersAndContact?.find(
        (m: AccountType & { userId: string }) =>
          m.id === markedBy || m.userId === markedBy
      ),
      true
    );

  const markedDoneDate =
    markedCompleteBy && getHumanizedDate(momentDate(updatedAt));

  if (!markedCompleteBy || !markedDoneDate) return null;

  return (
    <p
      className={classNames(
        "text-xs leading-tight text-grey-500 flex items-center gap-1 mt-1",
        messaging && "dark:text-white"
      )}
    >
      <div className="bg-green-600 text-green-200 rounded-full p-0.5">
        <CheckIcon className="h-3 w-3" />
      </div>
      <div className={classNames(todoTextColor[color], "flex gap-1")}>
        <span className="text-black dark:text-white font-medium">
          {markedCompleteBy}
        </span>
        <span
          className={classNames(
            {
              action: "action-300/50",
              grey: "text-grey-500",
              messaging: "action-300/50 dark:text-grey-500",
            }[color]
          )}
        >
          marked to-do as done
        </span>
        <span className="text-black dark:text-white font-medium">
          {markedDoneDate}
        </span>
      </div>
    </p>
  );
};

export default TodoDone;
