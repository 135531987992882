import { FC, useState } from "react";
import { TodoType } from "types/db/todos";

import useChat from "@hooks/use-chat";
import { AccountType } from "@lib/data/schemas/account";
import { ClientType } from "@lib/data/schemas/client";

import LoadingSpinner from "@components/LoadingSpinner";
import AddTodoButton from "@components/Todos/AddTodoButton";
import CompletedTodosButton from "@components/Todos/CompletedTodosButton";
import TodoList from "@components/Todos/TodoList";

interface TodosProps {
  client?: ClientType;
  messaging?: boolean;
  completeTodos: TodoType[];
  incompleteTodos: TodoType[];
  createTodo: () => void;
  isPrivateTodos?: boolean;
  assigneeOptions: (AccountType | ClientType)[];
}

const Todos: FC<TodosProps> = ({
  client,
  messaging = false,
  completeTodos,
  incompleteTodos,
  createTodo,
  isPrivateTodos = false,
  assigneeOptions,
}) => {
  const [isCompletedTodosOpen, setIsCompletedTodosOpen] =
    useState<boolean>(false);
  const clientId = client?.id;
  const { channels } = useChat(clientId);

  const titlelessLastTodos = [
    ...(incompleteTodos && incompleteTodos.filter((todo) => todo.title)),
    ...(incompleteTodos && incompleteTodos.filter((todo) => !todo.title)),
  ];

  if (!incompleteTodos && !completeTodos)
    return (
      <LoadingSpinner variant="transparent" className="h-9 mx-auto mb-4" />
    );

  return (
    <>
      <TodoList
        todos={titlelessLastTodos}
        contact={client}
        channel={channels[clientId]}
        messaging={messaging}
        isPrivateTodos={isPrivateTodos}
        createTodo={createTodo}
        assigneeOptions={assigneeOptions}
      />
      <AddTodoButton
        createTodo={createTodo}
        isPrivateTodos={isPrivateTodos}
        messaging={messaging}
      />
      {completeTodos?.length > 0 && (
        <CompletedTodosButton
          isOpen={isCompletedTodosOpen}
          setIsOpen={setIsCompletedTodosOpen}
          isPrivateTodos={isPrivateTodos}
          messaging={messaging}
        />
      )}
      {isCompletedTodosOpen && (
        <div className="pb-1">
          <TodoList
            todos={completeTodos}
            contact={client}
            channel={channels[clientId]}
            messaging={messaging}
            isPrivateTodos={isPrivateTodos}
            createTodo={createTodo}
            assigneeOptions={assigneeOptions}
          />
        </div>
      )}
    </>
  );
};

export default Todos;
