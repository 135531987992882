import { FC, ReactNode, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { kebabCase } from "lodash";

import { SVGIconProps } from "@lib/shared-types";

import { Button } from "@components/Button";
import Label from "@components/Form/Label";
import CheckIcon from "@components/Icons/CheckIcon";
import FilterIcon from "@components/Icons/FilterIcon";
import Transition from "@components/Transition";

type OptionType = {
  label: string;
  value: string;
  icon?: FC<SVGIconProps>;
  selected?: boolean;
  onClick: (value: string) => void;
};

const FilterDropdownOption: FC<OptionType> = ({
  label,
  icon: Icon,
  selected,
  value,
  onClick,
}) => (
  <div
    className="flex items-center cursor-pointer px-4 py-2 hover:bg-action-950 focus:outline-none focus:bg-action-950"
    onClick={() => onClick(value)}
  >
    <div className="flex items-center flex-1">
      {Icon && <Icon className="w-6 h-6 mr-2" />}
      <span className="text-black-ink">{label}</span>
    </div>
    {selected && <CheckIcon className="w-6 h-6" />}
  </div>
);

interface GroupedOptionsProps {
  label: string;
  options: OptionType[];
}

const GroupedOptions: FC<GroupedOptionsProps> = ({ label, options }) => (
  <div className="py-2">
    <Label className="px-4 py-2">{label}</Label>
    {options.map((option, index) => (
      <FilterDropdownOption key={index} {...option} />
    ))}
  </div>
);

interface FilterDropdownProps {
  className?: string;
  options?: GroupedOptionsProps[];
  footer?: ReactNode;
  totalSelected?: number;
  optionClassNames?: string;
}

const FilterDropdown: FC<FilterDropdownProps> = ({
  className,
  options,
  footer,
  totalSelected = 0,
  optionClassNames,
}) => {
  const ref = useRef<any>();
  const [isOpen, setIsOpen] = useState(false);

  const closeOptions = (e: any) => {
    if (
      !ref?.current?.contains(e.target) &&
      !e.target.className?.includes?.("ignore-click")
    ) {
      setIsOpen(false);
      return;
    }
  };

  useEffect(() => {
    document.addEventListener("mouseup", closeOptions);

    return () => {
      document.removeEventListener("mouseup", closeOptions);
    };
  }, []);

  const renderTriggerButton = () => {
    const shouldRenderSelected = totalSelected > 0;
    return (
      <Button
        icon={shouldRenderSelected ? undefined : <FilterIcon />}
        className="w-8 h-8"
        onClick={() => setIsOpen(true)}
        square
      >
        {shouldRenderSelected && (
          <span className="text-xs font-medium text-black-ink rounded-full bg-action-700 w-6 h-6">
            {totalSelected}
          </span>
        )}
      </Button>
    );
  };

  const renderOptions = isOpen && options && (
    <Transition
      ref={ref}
      show
      className={classNames(
        "z-50 origin-top-right absolute mt-2 right-0 rounded-md shadow-lg overflow-hidden w-72"
      )}
    >
      <div
        className={classNames(
          "rounded-md bg-white ring-1 ring-black/5 grid grid-cols-1 divide-y",
          optionClassNames
        )}
      >
        {options.map((group, index) => (
          <GroupedOptions
            key={`select-box-${kebabCase(group.label)}-${index}`}
            {...group}
          />
        ))}
        {footer}
      </div>
    </Transition>
  );

  return (
    <div className={classNames("relative inline-block", className)}>
      {renderTriggerButton()}
      {renderOptions}
    </div>
  );
};

export default FilterDropdown;
