import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66675 3.33325H4.66675V3.99992H3.33341C2.96522 3.99992 2.66675 4.2984 2.66675 4.66659V11.3333C2.66675 11.7014 2.96522 11.9999 3.33341 11.9999H12.6667C13.0349 11.9999 13.3334 11.7014 13.3334 11.3333V4.66659C13.3334 4.2984 13.0349 3.99992 12.6667 3.99992H6.66675V3.33325ZM4.00008 10.6666V5.33325H12.0001V10.6666H4.00008Z"
      />
      <path d="M9.33341 7.99992C9.33341 8.7363 8.73646 9.33325 8.00008 9.33325C7.2637 9.33325 6.66675 8.7363 6.66675 7.99992C6.66675 7.26354 7.2637 6.66659 8.00008 6.66659C8.73646 6.66659 9.33341 7.26354 9.33341 7.99992Z" />
    </svg>
  );
};

export default Icon;
