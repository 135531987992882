import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const PhoneIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.00288 6.81078C5.99936 6.62142 6.107 6.44649 6.27639 6.36179L8.44249 5.27875C8.7286 5.1357 9.07359 5.29437 9.15117 5.60469L9.89312 8.57244C9.95421 8.81679 9.82401 9.06843 9.58927 9.15972L9.03364 9.37579C8.78094 9.47406 8.65171 9.75663 8.75319 10.0081C9.88193 12.8046 11.8747 14.8017 14.7037 15.8904C14.8838 15.9597 15.0869 15.9131 15.2233 15.7766L15.7815 15.2185C15.9154 15.0846 16.1135 15.0378 16.2932 15.0977L18.5947 15.8649C18.8281 15.9427 18.9702 16.1787 18.9298 16.4214L18.5696 18.5822C18.5295 18.8233 18.3191 19.0008 18.0748 18.9946C10.9143 18.8115 6.1366 13.9965 6.00288 6.81078Z" />
    </svg>
  );
};

export default PhoneIcon;
