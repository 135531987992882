import { FC } from "react";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";

export interface TodoEmptyStateProps {
  title: string;
  Icon: FC;
  description: string;
  variant?: "default" | "shared";
  dark?: boolean;
}

const TodoEmptyState: FC<TodoEmptyStateProps> = ({
  title,
  Icon,
  description,
  variant = "default",
  dark = false,
}) => {
  const shared = variant === "shared";
  return (
    <div className="px-4">
      <div className={classNames(dark && "dark:grayscale dark:invert")}>
        <Icon />
      </div>
      <h2
        className={classNames(
          "font-medium pt-4 pb-1",
          shared && "text-action-300",
          dark && "dark:text-white"
        )}
      >
        {title}
      </h2>
      <p
        className={twMerge(
          "text-grey-500 text-sm max-w-[316px]",
          shared && "text-action-300/50",
          dark && "dark:text-grey-500"
        )}
      >
        {description}
      </p>
    </div>
  );
};

export default TodoEmptyState;
