import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 4H4L4 20H20V4ZM4 2C2.89543 2 2 2.89543 2 4V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V4C22 2.89543 21.1046 2 20 2H4Z"
        fill="#040506"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3242 14.2027C10.1289 14.398 9.81234 14.398 9.61708 14.2027L7.06075 11.6464C6.86549 11.4511 6.5489 11.4511 6.35364 11.6464L5.64653 12.3535C5.45127 12.5487 5.45127 12.8653 5.64653 13.0606L9.61707 17.0311C9.81234 17.2264 10.1289 17.2264 10.3242 17.0311L18.8095 8.54585C19.0047 8.35059 19.0047 8.03401 18.8095 7.83875L18.1024 7.13164C17.9071 6.93638 17.5905 6.93638 17.3952 7.13164L10.3242 14.2027Z"
        fill="#040506"
      />
    </svg>
  );
};

export default Icon;
