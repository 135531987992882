import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const RightArrowIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      fill="none"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18.0536 12.3509C18.2488 12.1556 18.2488 11.8391 18.0536 11.6438L13.8109 7.40116C13.6157 7.20589 13.2991 7.20589 13.1038 7.40116L12.3967 8.10826C12.2015 8.30352 12.2015 8.62011 12.3967 8.81537L14.5787 10.9973L6.4929 11C6.21676 11 6 11.2212 6 11.4973L6 12.4973C6 12.7735 6.22386 13.0044 6.5 13.0044L14.5787 12.9973L12.3967 15.1793C12.2014 15.3746 12.2014 15.6912 12.3967 15.8864L13.1038 16.5935C13.2991 16.7888 13.6157 16.7888 13.8109 16.5935L18.0536 12.3509Z" />
    </svg>
  );
};

export default RightArrowIcon;
