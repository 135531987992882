import { FC } from "react";
import classNames from "classnames";
import { TodoType } from "types/db/todos";

import { useAuth } from "@contexts/auth";
import { ClientType } from "@lib/data/schemas/client";
import isDarkMode from "@lib/utils/dark-mode";
import { getDueDateStatus, momentDate } from "@lib/utils/todos";

import Tag, { TagColorType } from "@components/Tag/Tag";

interface TodoTagsProps {
  variant?: "shared" | "private" | "messaging";
  client?: ClientType;
  customListTodos?: TodoType[];
  tagClassNames?: string;
  tagColor?: TagColorType;
}

const TodoTags: FC<TodoTagsProps> = ({
  variant = "private",
  client,
  customListTodos,
  tagClassNames,
  tagColor,
}) => {
  const { isOwner, oid, aid } = useAuth();
  if (!customListTodos?.length) return null;

  const isPrivate = variant === "private";
  const darkMode = variant === "messaging" && isDarkMode();

  const isClient = oid === client?.accountId;
  const assignedTodos = customListTodos?.filter((todo: TodoType) => {
    if (!todo.dueAt) return false;
    const assigneeId = todo?.assigneeId;
    return isClient
      ? assigneeId === client?.id
      : !assigneeId
      ? isOwner
      : assigneeId === aid;
  });

  const overdueTodos = assignedTodos?.filter((todo: TodoType) => {
    const dueDateType = getDueDateStatus(momentDate(todo.dueAt));
    return dueDateType === "overdue" || dueDateType === "today";
  });

  const tag = (color: TagColorType, todos: TodoType[]) => (
    <Tag
      className={classNames(
        "rounded-[4px] h-5 min-w-[20px] px-0.5",
        color === "grey" && "group-hover:bg-grey-900",
        tagClassNames
      )}
      color={color}
      isMd
      isSelected={!!todos.length}
      isInvisible={!todos}
    >
      {todos?.length || "0"}
    </Tag>
  );

  const getActive = (type: TagColorType) =>
    tag(
      tagColor
        ? tagColor
        : isPrivate
        ? type
        : darkMode
        ? "dark"
        : "light-action",
      customListTodos
    );

  return (
    <div className="ml-2 flex items-center">
      {overdueTodos?.length ? (
        <div className="flex gap-1">
          {tag("peach", overdueTodos)}
          {getActive("grey")}
        </div>
      ) : (
        getActive("action")
      )}
    </div>
  );
};

export default TodoTags;
