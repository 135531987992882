import { ReactNode } from "react";
import classNames from "classnames";

interface IconButtonProps {
  className?: string;
  icon: ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children?: React.ReactNode;
}

const IconButton: React.FC<IconButtonProps> = ({
  className,
  icon,
  onClick,
  children,
}) => {
  return (
    <button
      className={classNames(
        "relative hover:opacity-50 active:hover:opacity-80 focus:outline-none focus:ring-0",
        className
      )}
      onClick={onClick}
    >
      <>
        {icon}
        {children}
      </>
    </button>
  );
};

export default IconButton;
