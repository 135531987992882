import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const ViewOffIcon: FC<SVGIconProps> = ({ className }) => (
  <svg
    className={classNames("fill-current", className)}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.79287 18.2929C3.40235 18.6834 3.40235 19.3166 3.79287 19.7071C4.1834 20.0976 4.81656 20.0976 5.20709 19.7071L19.2071 5.70711C19.5976 5.31658 19.5976 4.68342 19.2071 4.29289C18.8166 3.90237 18.1834 3.90237 17.7929 4.29289L15.3521 6.73369C14.3499 6.28624 13.2286 6 12 6C6.375 6 3 12 3 12C3 12 4.17301 14.0854 6.28851 15.7973L3.79287 18.2929ZM7.71182 14.3739L9.14911 12.9367C9.05234 12.6419 9 12.3271 9 12C9 10.3431 10.3431 9 12 9C12.3271 9 12.6419 9.05235 12.9367 9.14911L13.8038 8.28197C13.2321 8.10233 12.6298 8 12 8C9.92585 8 8.15033 9.10982 6.78292 10.4358C6.21401 10.9875 5.75136 11.5433 5.40817 12C5.75136 12.4567 6.21401 13.0125 6.78292 13.5642C7.07437 13.8468 7.38435 14.1196 7.71182 14.3739Z"
    />
    <path d="M10.7968 17.9076C10.4161 17.8487 10.2895 17.3883 10.5618 17.116L11.5285 16.1493C11.6289 16.0489 11.7673 15.9971 11.9092 15.9993C11.9394 15.9998 11.9697 16 12 16C14.0741 16 15.8497 14.8902 17.2171 13.5642C17.786 13.0125 18.2486 12.4567 18.5918 12C18.3261 11.6464 17.9888 11.2334 17.5859 10.8088C17.394 10.6065 17.3911 10.2868 17.5882 10.0896L18.2947 9.38309C18.4883 9.18948 18.8026 9.18734 18.9919 9.38514C20.2948 10.7463 21 12 21 12C21 12 17.625 18 12 18C11.5866 18 11.1854 17.9676 10.7968 17.9076Z" />
    <path d="M14.8975 12.7803C14.6209 13.81 13.81 14.6209 12.7803 14.8975L14.8975 12.7803Z" />
  </svg>
);

export default ViewOffIcon;
