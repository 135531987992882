import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const LogIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 2C3 1.44772 3.44772 1 4 1H20C20.5523 1 21 1.44772 21 2V22C21 22.5523 20.5523 23 20 23H4C3.44772 23 3 22.5523 3 22V2ZM8 4C7.44772 4 7 4.44772 7 5C7 5.55228 7.44772 6 8 6H16C16.5523 6 17 5.55228 17 5C17 4.44772 16.5523 4 16 4H8ZM11 10.5C11 10.2239 11.2239 10 11.5 10H12.5C12.7761 10 13 10.2239 13 10.5V14H16.5C16.7761 14 17 14.2239 17 14.5V15.5C17 15.7761 16.7761 16 16.5 16H13V19.5C13 19.7761 12.7761 20 12.5 20H11.5C11.2239 20 11 19.7761 11 19.5V16H7.5C7.22386 16 7 15.7761 7 15.5V14.5C7 14.2239 7.22386 14 7.5 14H11V10.5Z"
      />
    </svg>
  );
};

export default LogIcon;
