import { FC } from "react";
import classNames from "classnames";
import { Moment } from "moment";

import { TodoColorType } from "@lib/shared-types";
import {
  getDueDateStatus,
  getHumanizedDate,
  momentDate,
} from "@lib/utils/todos";

import { PastDatesPicker } from "@components/Form/DatePicker";
import CalendarIcon from "@components/Icons/CalendarIcon";

const dueDateColors = {
  overdue: {
    grey: "bg-peach-950 text-peach-500 ",
    action: "bg-action-300/50 text-white",
    messaging: "dark:bg-peach-500/50 bg-action-300/50 text-white",
  },
  today: {
    grey: "bg-peach-600 text-white",
    action: "bg-peach-600 text-white",
    messaging: "bg-peach-600 text-white",
  },
  tomorrow: {
    grey: "bg-action-900 text-action-500",
    action: "bg-action-400 text-white bg-action-400",
    messaging: "dark:bg-grey-100 bg-action-400 text-white dark:bg-grey-200",
  },
  default: {
    grey: "bg-grey-950 group-hover:bg-grey-900 text-grey-500",
    action: "bg-action-300/10 text-action-300",
    messaging:
      "dark:bg-grey-500/20 dark:text-white bg-action-300/10 text-action-300 ",
  },
};

export interface TodoDueDatePickerProps {
  userIsAssigned: boolean;
  dueAt?: Date | { _seconds: number };
  color: TodoColorType;
  onDueDateChange: (date: Moment) => void;
  isComplete: boolean;
  shared?: boolean;
}

const TodoDueDatePicker: FC<TodoDueDatePickerProps> = ({
  dueAt,
  userIsAssigned,
  color,
  onDueDateChange,
  isComplete,
  shared = false,
}) => {
  const momentDueDate = momentDate(dueAt);
  const dueDateType = getDueDateStatus(momentDueDate);

  const colorScheme =
    isComplete || !userIsAssigned
      ? dueDateColors["default"][color]
      : dueDateColors[dueDateType][color];

  const when = getHumanizedDate(momentDueDate);

  const noDate = () => (
    <div
      className={classNames(
        "rounded-full border px-1.5 h-5 flex items-center",
        {
          grey: "text-grey-500 border-grey-800",
          action: "text-action-300 border-action-300/50",
          messaging:
            "dark:text-white dark:border-white/50 text-action-300 border-action-300/50",
        }[color]
      )}
    >
      <CalendarIcon className="w-3.5 h-3.5" />
    </div>
  );

  const existingDate = () => (
    <div
      className={classNames(
        "text-xs rounded-full px-2 h-5 flex items-center justify-center",
        colorScheme
      )}
    >
      {when}
    </div>
  );

  const content = dueAt ? existingDate : noDate;

  return isComplete ? (
    <div className="cursor-not-allowed">{content()}</div>
  ) : (
    <div
      className={classNames("hidden-date-input", shared ? "shared" : "private")}
      key={color}
    >
      <PastDatesPicker
        date={momentDueDate}
        CustomInputIcon={content}
        customInputIconClass="z-30 absolute group-hover:bg-grey-900"
        inputIconPosition="before"
        onChange={(e) => onDueDateChange(e.target.value)}
        placeholder={""}
        displayYear={false}
        showClearDate={false}
      />
    </div>
  );
};

export default TodoDueDatePicker;
