import { FC } from "react";
import classNames from "classnames";
import { TodoType } from "types/db/todos";

import { displayNameFromContact } from "@lib/contacts";
import { AccountType } from "@lib/data/schemas/account";
import { ClientType } from "@lib/data/schemas/client";
import { TodoColorType } from "@lib/shared-types";

import ClientAvatar from "@components/Client/ClientAvatar";
import Dropdown from "@components/Dropdown";
import CloseIcon from "@components/Icons/CloseIcon";
import SendToClientIcon from "@components/Icons/SendToClientIcon";

export interface AssignTodoPickerProps {
  assigneeOptions: (AccountType | ClientType)[];
  todo: TodoType;
  handleAssignId: (id: string) => void;
  color: TodoColorType;
}

const AssignTodoPicker: FC<AssignTodoPickerProps> = ({
  todo: { assigneeId, status },
  assigneeOptions,
  handleAssignId,
  color,
}) => {
  if (!assigneeOptions) return null;

  const isComplete = status === "complete";
  const assignedMember = assigneeOptions?.find((a) => a.id === assigneeId);

  const content = (
    <div className={classNames(isComplete && "cursor-not-allowed")}>
      {assignedMember ? (
        <div className={classNames(isComplete && "grayscale")}>
          <ClientAvatar client={assignedMember as ClientType} size="xxsmall" />
        </div>
      ) : (
        <div
          className={classNames(
            "rounded-full border px-1.5 h-5 flex items-center justify-center",
            {
              grey: "text-grey-500 border-grey-800",
              action: "text-action-300 border-action-300/50",
              messaging:
                "dark:text-white dark:border-white/50 text-action-300 border-action-300/50",
            }[color]
          )}
        >
          <SendToClientIcon className="h-4 w-4" />
        </div>
      )}
    </div>
  );

  return isComplete ? (
    content
  ) : (
    <Dropdown
      content={content}
      menuClasses="left-0 top-3 !rounded-lg !bg-white border border-grey-950 min-w-[180px]"
      buttonClasses="border-none hover:bg-transparent"
    >
      <div className="flex flex-col items-start">
        {assigneeOptions?.map((assignee: ClientType) => (
          <div
            onClick={() => handleAssignId(assignee.id)}
            key={assignee.id}
            className={classNames(
              "hover:bg-grey-950 px-2 py-1.5 w-full cursor-default",
              assignee.coachUserId && "border-b"
            )}
          >
            <div className="flex justify-between gap-2 items-center">
              <div className="flex gap-2 items-center">
                <ClientAvatar client={assignee as ClientType} size="xxsmall" />

                <div className="text-sm">
                  {displayNameFromContact(assignee)}
                </div>
              </div>
              {assignee.id === assigneeId && (
                <CloseIcon className="h-4 w-4 text-grey-500" />
              )}
            </div>
          </div>
        ))}
      </div>
    </Dropdown>
  );
};

export default AssignTodoPicker;
