import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.70577 11.366L12 7.15472L19.2942 11.3661L19.7942 10.5C20.0704 10.0218 19.9065 9.41016 19.4282 9.13402L12.5084 5.13886C12.2705 5.00153 11.9997 4.97303 11.7536 5.03789C11.6592 5.05967 11.5664 5.09564 11.4781 5.14659L4.5718 9.13397C4.09351 9.41012 3.92963 10.0217 4.20577 10.5L4.70577 11.366Z" />
      <path d="M4 18C3.44772 18 3 18.4477 3 19V20H21V19C21 18.4477 20.5523 18 20 18H4Z" />
      <path d="M8 12H6V17H8V12Z" />
      <path d="M11 12H13V17H11V12Z" />
      <path d="M18 12H16V17H18V12Z" />
    </svg>
  );
};

export default Icon;
