import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.44187 10.9865L8.2536 4.22499L12.1127 5.25903L10.8877 5.58724C10.3543 5.73018 10.0377 6.27852 10.1806 6.81199L10.9411 9.64999L10.3056 12.0217C10.0197 13.0887 8.92301 13.7218 7.85608 13.436C6.78915 13.1501 6.15598 12.0534 6.44187 10.9865ZM8.33698 15.5049C9.8391 15.5198 11.2383 14.6811 11.9294 13.3383L11.9924 13.5735C12.4986 15.4627 14.2759 16.6719 16.1577 16.5275L16.9695 19.5573L15.7706 19.8785C15.5039 19.95 15.3456 20.2242 15.4171 20.4909L15.6759 21.4568C15.7474 21.7235 16.0215 21.8818 16.2883 21.8104L21.1179 20.5163C21.3846 20.4448 21.5429 20.1706 21.4715 19.9039L21.2126 18.938C21.1412 18.6712 20.867 18.5129 20.6003 18.5844L18.9014 19.0396L18.055 15.8809C19.4566 14.9592 20.1777 13.212 19.7198 11.5029L17.908 4.74143C17.7651 4.20797 17.2168 3.89138 16.6833 4.03433L14.3361 4.66325C14.3902 4.18013 14.0848 3.7169 13.6009 3.58724L7.80531 2.03432C7.27185 1.89138 6.72351 2.20796 6.58057 2.74143L4.51002 10.4688C4.02762 12.2692 4.85362 14.1118 6.40513 14.9873L5.33723 18.9727L3.88835 18.5845C3.62162 18.513 3.34745 18.6713 3.27598 18.938L3.01716 19.904C2.94569 20.1707 3.10398 20.4449 3.37071 20.5163L4.8196 20.9045L6.75145 21.4222L8.20034 21.8104C8.46708 21.8819 8.74125 21.7236 8.81272 21.4569L9.07154 20.4909C9.14301 20.2242 8.98471 19.95 8.71798 19.8786L7.26909 19.4903L8.33698 15.5049ZM13.0116 9.64999L13.9242 13.0558C14.2101 14.1228 15.3068 14.7559 16.3737 14.47C17.4406 14.1842 18.0738 13.0875 17.7879 12.0206L16.235 6.225L13.751 6.89058L13.0116 9.64999Z"
      />
    </svg>
  );
};

export default Icon;
