import { FC, ReactNode } from "react";
import { UseFormMethods } from "react-hook-form";

import useWarnIfUnsavedChanges from "@hooks/use-warn-if-unsaved-changes";

interface Props {
  formMethods: UseFormMethods;
  children: ReactNode;
}

const FormWrapper: FC<Props> = ({ formMethods, children }) => {
  useWarnIfUnsavedChanges({
    isDirty: formMethods.formState.isDirty,
    isSubmitting: formMethods.formState.isSubmitting,
  });

  return <>{children}</>;
};

export default FormWrapper;
