import { getClientsTodos } from "api-services/definitions/todos";
import { useApiGet } from "api-services/endpoints";
import { TodoType } from "types/db/todos";

import { ClientType } from "@lib/data/schemas/client";

type ClientTodosType = { todos: TodoType[]; clientId: string };

export default function useAllClientsTodos(
  orgId: string,
  aid: string
): {
  clientIds: string[];
  clientsIncompleteTodos: TodoType[];
  clientTodos: TodoType[];
  clientsWithTodos: ClientType[];
  loading?: boolean;
} {
  const { data: allClientsTodos, loading } = useApiGet(
    getClientsTodos,
    {
      userId: orgId,
    },
    { accountId: aid, orgId },
    {
      dedupingInterval: 600000,
    }
  );

  const clientIds = allClientsTodos?.map((c: ClientTodosType) => c.clientId);

  const clientTodos = allClientsTodos
    ?.map((c: ClientTodosType) => c.todos)
    .flat();

  const clientsIncompleteTodos = clientTodos?.filter(
    (t: TodoType) => t.status === "incomplete"
  );
  const clientsWithTodos = allClientsTodos?.map((c) => c.clientData);

  return {
    clientIds: clientIds ?? [],
    clientsIncompleteTodos: clientsIncompleteTodos ?? [],
    clientTodos: clientTodos ?? [],
    clientsWithTodos: clientsWithTodos ?? [],
    loading,
  };
}
