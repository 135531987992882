import { FC } from "react";
import { BillableRequestStatus } from "@practice/sdk";

import CalendarChangeIcon from "@components/Icons/CalendarChangeIcon";
import DayIcon from "@components/Icons/DayIcon";

import { ModeType } from "./BillableRequestsModal";

const emptyStateInfo = {
  reviewer: {
    submitted: {
      icon: DayIcon,
      title: "No requests yet",
      description: "When members submit requests, you'll see them listed here.",
    },
    approved: {
      icon: CalendarChangeIcon,
      title: "No approved requests",
      description:
        "Check your pending requests, once requests are approved it cannot be undone.",
    },
    rejected: {
      icon: CalendarChangeIcon,
      title: "No declined requests",
      description:
        "Check your pending requests, once requests are declined it cannot be undone.",
    },
  },
  coach: {
    submitted: {
      icon: DayIcon,
      title: "No requests yet",
      description:
        "Once you submit a request, reviewers are notified immediately.",
    },
    approved: {
      icon: CalendarChangeIcon,
      title: "No approved requests",
      description: "Reviewers are always notified of any pending requests.",
    },
    rejected: {
      icon: CalendarChangeIcon,
      title: "No declined requests",
      description:
        "When a request is declined by the reviewer, it is final. However, you can submit another request.",
    },
  },
};

interface BillableRequestEmptyStateProps {
  mode?: ModeType;
  status?: BillableRequestStatus;
}

const BillableRequestEmptyState: FC<BillableRequestEmptyStateProps> = ({
  mode,
  status,
}) => {
  if (!mode || !status) return null;
  const { icon: Icon, title, description } = emptyStateInfo[mode][status];
  return (
    <div className="flex h-full items-center justify-center flex-col gap-6 text-grey-500 text-center">
      <Icon className="h-8 w-8" />
      <div className="flex flex-col gap-1">
        <h1>{title}</h1>
        <h3 className="text-sm">{description}</h3>
      </div>
    </div>
  );
};

export default BillableRequestEmptyState;
