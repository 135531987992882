import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6538 7.14645C11.8491 6.95118 12.1656 6.95118 12.3609 7.14645L15.6035 10.3891C15.7988 10.5843 15.7988 10.9009 15.6035 11.0962L14.8964 11.8033C14.7012 11.9986 14.3846 11.9986 14.1893 11.8033L13.0074 10.6213L13 10.6118V16.5C13 16.7761 12.7761 17 12.5 17H11.5C11.2238 17 11 16.7761 11 16.5V10.6287L9.82537 11.8033C9.63011 11.9986 9.31352 11.9986 9.11826 11.8033L8.41116 11.0962C8.21589 10.9009 8.21589 10.5843 8.41116 10.3891L11.6538 7.14645Z"
        fill="#040506"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 2C4.44772 2 4 2.44772 4 3V21C4 21.5523 4.44772 22 5 22H19C19.5523 22 20 21.5523 20 21V6C20 3.79086 18.2091 2 16 2H5ZM6 4V20H18V6C18 4.89543 17.1046 4 16 4H6Z"
        fill="#040506"
      />
    </svg>
  );
};

export default Icon;
