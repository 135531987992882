import React, { FC, useEffect, useState } from "react";
import {
  isInclusivelyAfterDay,
  isInclusivelyBeforeDay,
  SingleDatePicker,
} from "react-dates";
import { Controller } from "react-hook-form";
import moment, { Moment } from "moment";

import InputContainerForm from "./InputContainerForm";
import { FormControlProps } from "./types";

type InputArg = {
  target: {
    value: Moment;
  };
};

interface DatePickerProps {
  date: Moment;
  disabled?: boolean;
  placeholder?: string;
  onChange: (data: InputArg) => void;
  displayFormat?: string;
  showClearDate?: boolean;
  afterDate?: Moment;
  startDate?: Moment;
}

interface PastDatesPickerProps {
  id?: string;
  date: Moment;
  onChange: (data: InputArg) => void;
  placeholder: string;
  displayYear: boolean;
  showClearDate: boolean;
  required?: boolean;
  showDefaultInputIcon?: boolean;
  CustomInputIcon?: any;
  customInputIconClass?: string;
  inputIconPosition?: string;
  regular?: boolean;
  customDisplayFormat?: string;
  reopenPickerOnClearDate?: boolean;
  anchorDirection?: string;
  withPortal?: boolean;
  overrideMobilePortal?: boolean;
  enableHorizontalOrientationLgHeight?: boolean;
  isOutsideRange?: (d: string) => any;
  setCustomIconFocus?: (args: boolean) => any;
  focus?: boolean;
  openDirection?: string;
}

interface RenderMonthProps {
  onYearSelect: (currentMonth: moment.Moment, newMonthVal: string) => void;
  onMonthSelect: (currentMonth: moment.Moment, newMonthVal: string) => void;
  month: moment.Moment;
}

interface DatePicketInputProps extends FormControlProps {
  displayFormat?: string;
  showClearDate?: boolean;
  afterDate?: Moment;
  startDate?: Moment;
}

const DatePicker: FC<DatePickerProps> = ({
  date,
  onChange,
  disabled = false,
  placeholder,
  displayFormat = "dddd, MMMM D",
  showClearDate = false,
  afterDate,
  startDate,
}) => {
  const [focused, setFocused] = useState(false);

  const checkOutsideRange = (day: Moment) => {
    if (afterDate) {
      const isInclusivelyBeforeAfterDate = isInclusivelyBeforeDay(
        day,
        afterDate
      );

      if (startDate) {
        return (
          !isInclusivelyAfterDay(day, startDate) ||
          !isInclusivelyBeforeAfterDate
        );
      }
      return !isInclusivelyBeforeAfterDate;
    }
    return !isInclusivelyAfterDay(day, moment());
  };

  return (
    <SingleDatePicker
      id="date-picker"
      numberOfMonths={1}
      onDateChange={(date) => onChange({ target: { value: date } })}
      onFocusChange={({ focused }) => setFocused(focused)}
      focused={focused}
      date={date}
      readOnly={true}
      noBorder={true}
      displayFormat={displayFormat}
      hideKeyboardShortcutsPanel
      withPortal={window.innerWidth < 400}
      disabled={disabled}
      placeholder={placeholder}
      showClearDate={showClearDate}
      isOutsideRange={checkOutsideRange}
    />
  );
};

export const PastDatesPicker: FC<PastDatesPickerProps> = ({
  id = "date-picker",
  date,
  onChange,
  placeholder,
  displayYear,
  showClearDate = false,
  required = false,
  showDefaultInputIcon = false,
  CustomInputIcon = null,
  customInputIconClass = "",
  inputIconPosition = null,
  regular = false,
  customDisplayFormat = "",
  reopenPickerOnClearDate = false,
  anchorDirection = "",
  openDirection = "",
  withPortal = false,
  overrideMobilePortal = false,
  isOutsideRange,
  focus = false,
  setCustomIconFocus,
}) => {
  const [focused, setFocused] = useState(focus);

  useEffect(() => {
    setFocused(focus);
  }, [focus]);

  const renderMonthElement: FC<RenderMonthProps> = ({
    month,
    onMonthSelect,
    onYearSelect,
  }) => {
    if (!displayYear) return <div>{moment(month, "M").format("MMMM")}</div>;
    let i;
    const years = [];
    const range = moment().add(10, "y").year();
    for (i = range; i >= range - 100; i--) {
      years.push(
        <option value={i} key={`year-${i}`}>
          {i}
        </option>
      );
    }
    return (
      <div
        className="flex justify-center -mt-2"
        onClick={() => setFocused(true)}
      >
        <select
          value={month.month()}
          onChange={(e) => onMonthSelect(month, e.target.value)}
          className="border border-grey-900 rounded-tl-sm rounded-bl-sm focus:outline-none focus:ring-0"
        >
          {moment.monthsShort().map((label, value) => (
            <option value={value} key={value}>
              {label}
            </option>
          ))}
        </select>
        <select
          value={month.year()}
          onChange={(e) => onYearSelect(month, e.target.value)}
          className="border border-grey-900 rounded-tr-sm rounded-br-sm focus:outline-none focus:ring-0"
        >
          {years}
        </select>
      </div>
    );
  };

  return (
    <SingleDatePicker
      id={id}
      numberOfMonths={1}
      onDateChange={(date) => {
        onChange({ target: { value: date } });
        setFocused(false);
        setCustomIconFocus && setCustomIconFocus(false);
      }}
      onFocusChange={({ focused: focusValue }) => {
        setFocused(focusValue);
      }}
      focused={focused}
      date={date}
      readOnly={true}
      noBorder={true}
      showClearDate={showClearDate}
      displayFormat={
        customDisplayFormat
          ? customDisplayFormat
          : displayYear
          ? "MMMM D, YYYY"
          : "MMMM D"
      }
      hideKeyboardShortcutsPanel
      withPortal={
        withPortal || (!overrideMobilePortal && window.innerWidth < 400)
      }
      isOutsideRange={isOutsideRange ? (d) => isOutsideRange(d) : () => false}
      renderMonthElement={renderMonthElement}
      placeholder={placeholder ? placeholder : "Date"}
      required={required}
      customInputIcon={<CustomInputIcon className={customInputIconClass} />}
      inputIconPosition={inputIconPosition}
      regular={regular}
      showDefaultInputIcon={showDefaultInputIcon}
      reopenPickerOnClearDate={reopenPickerOnClearDate}
      anchorDirection={anchorDirection || "left"}
      openDirection={openDirection || "down"}
    />
  );
};

/** A react-hook-form compatible date picker */
export const DatePickerInput: FC<DatePicketInputProps> = (props) => {
  return (
    <InputContainerForm {...props}>
      <Controller
        control={props.control}
        name={props.name}
        rules={{ validate: props.validate }}
        render={({ field: { value, onChange } }) => (
          <DatePicker
            date={value}
            disabled={props.disabled}
            placeholder={props.placeholder}
            onChange={(e) => onChange(e.target.value)}
            displayFormat={props.displayFormat}
            showClearDate={props.showClearDate}
            afterDate={props.afterDate}
            startDate={props.startDate}
          />
        )}
      />
    </InputContainerForm>
  );
};

export default DatePicker;
