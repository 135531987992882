import { useState } from "react";
import {
  GetSessionBillingCyclesTotalOverTimeApi,
  GetSessionBillingCycleSummary200Response as CycleSummaryType,
  SessionBillingCycleSummaryApi,
} from "@practice/sdk";
import useSWR from "swr";

import { useAuth } from "@contexts/auth";
import useLogger from "@hooks/use-logger";
import { useRequestIdGenerator } from "@hooks/use-request-id-generator";
import { useSDKApi } from "@hooks/use-sdk-api";
import { catchErrorSDK } from "@lib/utils/catch-error-client";

const useSessionBillingCycleSummaryAPI = () => {
  const { oid } = useAuth();
  const { logger } = useLogger("useSessionBillingCycleSummaryAPI");
  const [isCycleSummaryFetching, setIsCycleSummaryFetching] =
    useState<boolean>(false);
  const [errorCycleSummary, setCycleSummaryError] = useState<string | null>(
    null
  );
  const [cycleSummary, setCycleSummary] = useState<CycleSummaryType | null>(
    null
  );
  const generateRequestId = useRequestIdGenerator(
    "useSessionBillingCycleSummaryAPI"
  );
  const sessionBillingCycleSummaryApi = useSDKApi(
    SessionBillingCycleSummaryApi
  );

  const getCycleSummary = async (memberId: string, start: Date, end: Date) => {
    if (!oid) return;

    setIsCycleSummaryFetching(true);
    try {
      const result =
        await sessionBillingCycleSummaryApi.getSessionBillingCycleSummary({
          organizationId: oid,
          xRequestId: generateRequestId(),
          start,
          end,
          memberId,
        });

      setCycleSummary(result);
    } catch (error: unknown) {
      const fallBackMessage = "Failed to fetch the summary of the cycle";
      logger.error(fallBackMessage, error);
      const errorMessage = await catchErrorSDK(error, fallBackMessage);
      setCycleSummaryError(errorMessage);
    } finally {
      setIsCycleSummaryFetching(false);
    }
  };

  return {
    cycleSummary,
    isCycleSummaryFetching,
    errorCycleSummary,
    getCycleSummary,
  };
};

/**
 * Gets the total overtime for a member
 * */
export const useSessionBillingOvertimeAPI = (memberId?: string) => {
  const { oid } = useAuth();
  const generateRequestId = useRequestIdGenerator(
    "use-session-billing-overtime-api"
  );
  const sessionBillingCyclesTotalOverTimeApi = useSDKApi(
    GetSessionBillingCyclesTotalOverTimeApi
  );

  const swrData = useSWR(
    `organizations/${oid}/session-billing/cycles/total-over-time?memberId=${memberId}`,
    async () => {
      if (!oid || !memberId) return;
      return await sessionBillingCyclesTotalOverTimeApi.getSessionBillingCyclesTotalOverTime(
        {
          organizationId: oid,
          xRequestId: generateRequestId(),
          memberId,
        }
      );
    },
    { dedupingInterval: 600000 }
  );

  return {
    data: swrData.data,
    error: swrData.error,
    isLoading: !swrData.data && !swrData.error,
  };
};

export default useSessionBillingCycleSummaryAPI;
