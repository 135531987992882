import { FC } from "react";
import classNames from "classnames";

import LeftArrowIcon from "@components/Icons/LeftArrowIcon";
import RightArrowIcon from "@components/Icons/RightArrowIcon";
import { Tooltip } from "@components/Tooltips/Tooltip";

type ArrowPaginationButtonsVariants = "default" | "white-label" | "green";
type StyleDateType = {
  divide: string;
  button: string;
  buttonStates: string;
  icon?: string;
};
type StylesType = Record<ArrowPaginationButtonsVariants, StyleDateType>;

const styles: StylesType = {
  default: {
    divide: "divide-grey-900",
    button: "bg-grey-950",
    buttonStates: "hover:bg-grey-900 active:bg-grey-800",
  },
  "white-label": {
    divide: "divide-foreground/25",
    button: "bg-foreground/7",
    buttonStates: "hover:bg-foreground/20 active:bg-foreground/20",
  },
  green: {
    divide: "divide-green-900",
    button: "bg-green-800",
    buttonStates: "hover:bg-green-800/90 active:bg-green-800",
    icon: "text-green-200/50",
  },
};

interface NavButtonProps {
  direction: "left" | "right";
  disabled?: boolean;
  onClick: () => void;
  tooltip?: string;
  variant?: ArrowPaginationButtonsVariants;
}

const NavButton: FC<NavButtonProps> = ({
  variant = "default",
  direction,
  onClick,
  disabled,
  tooltip,
}) => {
  const style = styles[variant];
  const isLeftDirection = direction === "left";
  const Icon = isLeftDirection ? LeftArrowIcon : RightArrowIcon;

  const renderButton = (
    <button
      className={classNames(
        "p-1",
        style?.button,
        !disabled && `cursor-pointer ${style?.buttonStates}`,
        isLeftDirection ? "rounded-l-lg" : "rounded-r-lg"
      )}
      onClick={onClick}
      disabled={disabled}
    >
      <Icon className={disabled ? style?.icon ?? "text-grey-800" : ""} />
    </button>
  );

  const renderTooltip = tooltip ? (
    <Tooltip contentClassName="px-3 py-1 !text-xs" trigger={renderButton}>
      {tooltip}
    </Tooltip>
  ) : null;

  return disabled || !tooltip ? renderButton : renderTooltip;
};

export interface ArrowPaginationButtonsProps {
  variant?: ArrowPaginationButtonsVariants;
  className?: string;
  onClickPrevious: () => void;
  onClickNext: () => void;
  disablePrevious?: boolean;
  disableNext?: boolean;
  tooltipPrevious?: string;
  tooltipNext?: string;
}

const ArrowPaginationButtons: FC<ArrowPaginationButtonsProps> = ({
  variant = "default",
  className,
  onClickPrevious,
  onClickNext,
  disablePrevious = false,
  disableNext = false,
  tooltipPrevious,
  tooltipNext,
}) => {
  const style = styles[variant];
  return (
    <div className={classNames("flex divide-x h-8", style?.divide, className)}>
      <NavButton
        variant={variant}
        direction="left"
        onClick={onClickPrevious}
        disabled={disablePrevious}
        tooltip={tooltipPrevious}
      />
      <NavButton
        variant={variant}
        direction="right"
        onClick={onClickNext}
        disabled={disableNext}
        tooltip={tooltipNext}
      />
    </div>
  );
};

export default ArrowPaginationButtons;
