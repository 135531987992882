import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const ViewOnIcon: FC<SVGIconProps> = ({ className }) => (
  <svg
    className={classNames("fill-current", className)}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12ZM13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 18C6.375 18 3 12 3 12C3 12 6.375 6 12 6C17.625 6 21 12 21 12C21 12 17.625 18 12 18ZM17.2171 13.5642C17.786 13.0125 18.2486 12.4567 18.5918 12C18.2486 11.5433 17.786 10.9875 17.2171 10.4358C15.8497 9.10982 14.0741 8 12 8C9.92585 8 8.15033 9.10982 6.78292 10.4358C6.21401 10.9875 5.75136 11.5433 5.40817 12C5.75136 12.4567 6.21401 13.0125 6.78292 13.5642C8.15033 14.8902 9.92585 16 12 16C14.0741 16 15.8497 14.8902 17.2171 13.5642Z"
    />
  </svg>
);

export default ViewOnIcon;
