import React, { FC } from "react";

import { InputError, useInputError } from "./InputError";
import InputHelper from "./InputHelper";
import { FormControlProps } from "./types";

const InputContainerForm: FC<FormControlProps> = ({
  name,
  label,
  errors,
  helperText,
  children,
}) => {
  const error = useInputError(errors, name);

  const renderHelper = helperText && <InputHelper>{helperText}</InputHelper>;

  const renderError = error && <InputError error={error} />;

  return (
    <div className="mt-6 mb-4">
      <label htmlFor="about" className="block text-sm leading-5 text-grey-500">
        {label}
      </label>
      <div className="mt-1">
        <div className="rounded-md">{children}</div>
      </div>
      {renderHelper}
      {renderError}
    </div>
  );
};

export default InputContainerForm;
