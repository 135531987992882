import AlarmOnIcon from "@components/Icons/AlarmOnIcon";
import BankIcon from "@components/Icons/BankIcon";
import BirthdayIcon from "@components/Icons/BirthdayIcon";
import CalendarIcon from "@components/Icons/CalendarIcon";
import CameraLightIcon from "@components/Icons/CameraLightIcon";
import ChampagneIcon from "@components/Icons/ChampagneIcon";
import CheckLightIcon from "@components/Icons/CheckLightIcon";
import ClientIcon from "@components/Icons/ClientIcon";
import ClockIcon from "@components/Icons/ClockIcon";
import CompanyIcon from "@components/Icons/CompanyIcon";
import CreditCardIcon from "@components/Icons/CreditCardIcon";
import DocGraphIcon from "@components/Icons/DocGraphIcon";
import DocInvoiceIcon from "@components/Icons/DocInvoiceIcon";
import DocumentIcon from "@components/Icons/DocumentIcon";
import Flag2Icon from "@components/Icons/Flag2Icon";
import FormIcon from "@components/Icons/FormIcon";
import GhostyIcon from "@components/Icons/GhostyIcon";
import GroupIcon from "@components/Icons/GroupIcon";
import HeartOutlineIcon from "@components/Icons/HeartOutlineIcon";
import HouseIcon from "@components/Icons/HouseIcon";
import InfoIcon from "@components/Icons/InfoIcon";
import InstagramIcon from "@components/Icons/InstagramIcon";
import InvoiceIcon from "@components/Icons/InvoiceIcon";
import LinkedinIcon from "@components/Icons/LinkedinIcon";
import LocationIcon from "@components/Icons/LocationIcon";
import LockCloseIcon from "@components/Icons/LockClose";
import MailIcon from "@components/Icons/MailIcon";
import MicrophoneIcon from "@components/Icons/MicrophoneIcon";
import NoteIcon from "@components/Icons/NoteIcon";
import PetIcon from "@components/Icons/PetIcon";
import PhoneIcon from "@components/Icons/PhoneIcon";
import PictureIcon from "@components/Icons/PictureIcon";
import PinnedIcon from "@components/Icons/PinnedIcon";
import RadioOnIcon from "@components/Icons/RadioOnIcon";
import ScaleIcon from "@components/Icons/ScaleIcon";
import SmartPhoneIcon from "@components/Icons/SmartPhoneIcon";
import SmileIcon from "@components/Icons/SmileIcon";
import SmileOffIcon from "@components/Icons/SmileOffIcon";
import SocialWebIcon from "@components/Icons/SocialWebIcon";
import StarIcon from "@components/Icons/StarIcon";
import TaxesIcon from "@components/Icons/TaxesIcon";
import TextboxIcon from "@components/Icons/TextboxIcon";
import TwitterIcon from "@components/Icons/TwitterIcon";
import UploadIcon from "@components/Icons/UploadIcon";

export const CUSTOM_FIELD_ICONS: { [key: string]: any } = {
  ic_text_box: TextboxIcon,
  ic_radio_on: RadioOnIcon,
  ic_check_light: CheckLightIcon,
  ic_scale: ScaleIcon,
  ic_bank: BankIcon,
  ic_upload: UploadIcon,
  ic_doc_graph: DocGraphIcon,
  ic_doc_invoice: DocInvoiceIcon,
  ic_doc_text: DocumentIcon,
  ic_dollar: InvoiceIcon,
  ic_pinned: PinnedIcon,
  ic_calendar: CalendarIcon,
  ic_form: FormIcon,
  ic_credit_card: CreditCardIcon,
  ic_taxes: TaxesIcon,
  ic_company: CompanyIcon,
  ic_lock: LockCloseIcon,
  ic_flag: Flag2Icon,
  ic_smile_on: SmileIcon,
  ic_smile_off: SmileOffIcon,
  ic_info: InfoIcon,
  ic_client: ClientIcon,
  ic_group: GroupIcon,
  ic_ghost: GhostyIcon,
  ic_star: StarIcon,
  ic_heart: HeartOutlineIcon,
  ic_champagne: ChampagneIcon,
  ic_birthday: BirthdayIcon,
  ic_pet: PetIcon,
  ic_house: HouseIcon,
  ic_microphone_light: MicrophoneIcon,
  ic_picture: PictureIcon,
  ic_smart_phone: SmartPhoneIcon,
  ic_alarm: AlarmOnIcon,
  ic_camera_light: CameraLightIcon,
  ic_phone: PhoneIcon,
  ic_location: LocationIcon,
  ic_social_web: SocialWebIcon,
  ic_mail: MailIcon,
  ic_linkedin: LinkedinIcon,
  ic_twitter: TwitterIcon,
  ic_instagram: InstagramIcon,
  ic_clock: ClockIcon,
  ic_notes: NoteIcon,
};
