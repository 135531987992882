import { FC, SelectHTMLAttributes } from "react";
import classNames from "classnames";

type SelectboxVariantTypes = "default" | "white-label";

export type SelectboxOptionType = {
  label: string;
  value: string;
  onClick?: () => void;
};

export interface SelectboxProps
  extends SelectHTMLAttributes<HTMLSelectElement> {
  options: SelectboxOptionType[];
  value: string;
  variant?: SelectboxVariantTypes;
}

const styles = {
  default: {
    wrapper: "bg-grey-950",
    option: "",
  },
  "white-label": {
    wrapper: "bg-foreground/7",
    option: "bg-background/50 text-foreground",
  },
};

const Selectbox: FC<SelectboxProps> = ({
  className,
  options,
  value,
  variant = "default",
  ...rest
}) => {
  const style = styles[variant];
  return (
    <select
      className={classNames(
        "border-0 focus:outline-none focus:ring-0 focus:shadow-none rounded-lg text-sm font-medium py-1",
        style?.wrapper,
        className
      )}
      value={value}
      {...rest}
    >
      {options.map(({ label, value }, index) => (
        <option
          className={style?.option}
          key={`select-box-${index}-${value}`}
          value={value}
        >
          {label}
        </option>
      ))}
    </select>
  );
};

export default Selectbox;
