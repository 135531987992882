import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const CalendarChangeIcon: FC<SVGIconProps> = ({ className }) => (
  <svg
    className={classNames("fill-current", className)}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15.1381 5.41413C15.3984 5.15378 15.3984 4.73167 15.1381 4.47132L14.1953 3.52851C13.9349 3.26816 13.5128 3.26816 13.2525 3.52851L6.66667 10.1143L4.08088 7.52851C3.82053 7.26817 3.39842 7.26816 3.13807 7.52851L2.19526 8.47132C1.93491 8.73167 1.93491 9.15378 2.19526 9.41413L5.72386 12.9427C6.24456 13.4634 7.08878 13.4634 7.60948 12.9427L15.1381 5.41413Z" />
    <path d="M21.8047 28.4713C22.065 28.7317 22.4872 28.7317 22.7475 28.4713L25.3333 25.8855L27.9191 28.4713C28.1795 28.7317 28.6016 28.7317 28.8619 28.4713L29.8047 27.5285C30.0651 27.2682 30.0651 26.8461 29.8047 26.5857L27.2189 23.9999L29.8047 21.4141C30.065 21.1538 30.065 20.7317 29.8047 20.4713L28.8619 19.5285C28.6015 19.2682 28.1794 19.2682 27.9191 19.5285L25.3333 22.1143L22.7475 19.5285C22.4872 19.2682 22.0651 19.2682 21.8047 19.5285L20.8619 20.4713C20.6016 20.7317 20.6016 21.1538 20.8619 21.4141L23.4477 23.9999L20.8619 26.5857C20.6015 26.8461 20.6015 27.2682 20.8619 27.5285L21.8047 28.4713Z" />
    <path d="M18.6623 6.76031C19.4245 6.86882 20.156 7.06926 20.8458 7.34971C23.8678 8.5782 26.0982 11.3425 26.5727 14.6707C26.6767 15.3997 27.2636 15.9999 28 15.9999C28.7364 15.9999 29.3411 15.4009 29.2604 14.669C28.7693 10.2183 25.8445 6.50318 21.8501 4.87937C20.8475 4.4718 19.7781 4.19604 18.664 4.07298C17.9321 3.99213 17.3333 4.59687 17.3333 5.33325C17.3333 6.06963 17.9333 6.65652 18.6623 6.76031Z" />
    <path d="M8.09394 17.3292C7.98999 16.6002 7.40305 15.9999 6.66667 15.9999C5.93029 15.9999 5.32554 16.5989 5.4063 17.3309C5.89812 21.7883 8.8311 25.5079 12.8348 27.1279C13.8321 27.5314 14.8954 27.8045 16.0027 27.9269C16.7346 28.0077 17.3333 27.403 17.3333 26.6666C17.3333 25.9302 16.7334 25.3433 16.0044 25.2395C15.2474 25.1318 14.5207 24.9333 13.835 24.6559C10.8059 23.4303 8.56925 20.6625 8.09394 17.3292Z" />
  </svg>
);

export default CalendarChangeIcon;
