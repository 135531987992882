import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const TextboxIcon: FC<SVGIconProps> = ({ className, style }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      style={style}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M21 3V7H19V6C19 5.44772 18.5523 5 18 5H13V18C13 18.5523 13.4477 19 14 19H15V21H9V19H10C10.5523 19 11 18.5523 11 18V5H6C5.44772 5 5 5.44772 5 6V7H3V3H21Z" />
    </svg>
  );
};

export default TextboxIcon;
