import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7 2C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H7.5C7.77614 22 8 21.7761 8 21.5V20.5C8 20.2239 7.77614 20 7.5 20H7C5.34315 20 4 18.6569 4 17V7C4 5.34315 5.34315 4 7 4H17C18.6569 4 20 5.34315 20 7V9.5C20 9.77614 20.2239 10 20.5 10H21.5C21.7761 10 22 9.77614 22 9.5V7C22 4.23858 19.7614 2 17 2H7ZM12.617 18.2028C12.8123 18.398 13.1289 18.398 13.3241 18.2028L20.3952 11.1317C20.5905 10.9364 20.907 10.9364 21.1023 11.1317L21.8094 11.8388C22.0047 12.0341 22.0047 12.3507 21.8094 12.5459L13.3241 21.0312C13.1289 21.2265 12.8123 21.2265 12.617 21.0312L8.64647 17.0606C8.45121 16.8654 8.45121 16.5488 8.64647 16.3535L9.35358 15.6464C9.54884 15.4512 9.86542 15.4512 10.0607 15.6464L12.617 18.2028Z" />
    </svg>
  );
};

export default Icon;
