import { createContext, FC, ReactNode, useContext } from "react";

import useSessionBillingCycleSummaryAPI from "./use-session-billing-cycle-summary-api";
import useSessionBillingCyclesAPI from "./use-session-billing-cycles-api";

type UseSessionBillingCyclesAPI = ReturnType<typeof useSessionBillingCyclesAPI>;
type UseSessionBillingCycleSummaryAPI = ReturnType<
  typeof useSessionBillingCycleSummaryAPI
>;

export type SessionBillingContextType = UseSessionBillingCyclesAPI &
  UseSessionBillingCycleSummaryAPI;
const SessionBillingContext = createContext<SessionBillingContextType>({
  isCyclesFetching: false,
  cycles: [],
  getCycleSummary: async () => {},
  isCycleSummaryFetching: false,
  cycleSummary: {
    amount: 0,
    totalSessions: 0,
    sessionsGroupedByRate: [],
    sessionsGroupedByClient: [],
    paid: false,
  },
  errorCycleSummary: null,
});

export const useSessionBillingContext = () => useContext(SessionBillingContext);

interface SessionBillingProviderProps {
  children: ReactNode;
}
export const SessionBillingProvider: FC<SessionBillingProviderProps> = ({
  children,
}) => {
  const { isCyclesFetching, cycles } = useSessionBillingCyclesAPI();
  const {
    getCycleSummary,
    isCycleSummaryFetching,
    cycleSummary,
    errorCycleSummary,
  } = useSessionBillingCycleSummaryAPI();

  return (
    <SessionBillingContext.Provider
      value={{
        isCyclesFetching,
        cycles,
        getCycleSummary,
        isCycleSummaryFetching,
        cycleSummary,
        errorCycleSummary,
      }}
    >
      {children}
    </SessionBillingContext.Provider>
  );
};
