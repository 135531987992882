import { useEffect } from "react";

export const usePageVisibility = (onPageVisible, onPageHidden) => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") onPageVisible();
      if (document.visibilityState === "hidden") onPageHidden();
    };

    document.addEventListener(
      "visibilitychange",
      handleVisibilityChange,
      false
    );

    return () =>
      document.addEventListener(
        "visibilitychange",
        handleVisibilityChange,
        false
      );
  }, [onPageVisible, onPageHidden]);
};
