import {
  BillingRate,
  BillingRateStatus,
  GetSessionBillingCycles200ResponseInner,
  SessionBillingCountingRule,
  SessionBillingCycle,
} from "@practice/sdk";
import { compact, isEmpty, kebabCase, orderBy } from "lodash";
import moment from "moment";

export interface RateType {
  title: string;
  value: string;
  icon?: string;
}

export type CycleType = GetSessionBillingCycles200ResponseInner;

export type OptionItemType = {
  label: string;
  value: string;
  start?: Date;
  end?: Date;
};

export type ExtendedRateType = RateType & {
  id: string;
  status: BillingRateStatus;
  overwriting?: BillingRate["overwriting"];
};

export type FormDataType = {
  currency: string;
  payPeriod: SessionBillingCycle;
  payPeriodStart: string;
  firstPayPeriod: "next" | "last";
  countingRule: SessionBillingCountingRule;
  acceptedOutcomes: string[];
  rateTypes: RateType[];
  timeZone: string;
};

export type BillingStatusType = "paid" | "unpaid";

export type BillingSummaryCycleType = {
  id: string;
  status: BillingStatusType;
  date: Date;
  title: string;
  total: number;
};

export const getValidRateTypes = (rateTypes: RateType[]) => compact(rateTypes);

export const payPeriodOptions = [
  { label: "Weekly", value: SessionBillingCycle.Weekly },
  { label: "Bi-weekly", value: SessionBillingCycle.BiWeekly },
  { label: "Monthly", value: SessionBillingCycle.Monthly },
];

export const getPayPeriodLabel = (payPeriod: SessionBillingCycle) =>
  payPeriodOptions.find((item) => item.value === payPeriod)?.label ?? "Monthly";

export const weekDaysNumbers: Record<string, number> = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7,
};

export const payPeriodStartOptions = [
  { label: "Sunday", value: "sunday" },
  { label: "Monday", value: "monday" },
  { label: "Tuesday", value: "tuesday" },
  { label: "Wednesday", value: "wednesday" },
  { label: "Thursday", value: "thursday" },
  { label: "Friday", value: "friday" },
  { label: "Saturday", value: "saturday" },
];

export const getFirstPayPeriodOptions = (periodStart: string) => {
  const selectedPeriodStartLabel =
    payPeriodStartOptions.find((item) => item.value === periodStart)?.label ??
    "Monday";

  return [
    { label: `Last ${selectedPeriodStartLabel}`, value: "last" },
    { label: `Next ${selectedPeriodStartLabel}`, value: "next" },
  ];
};

export const formattedCountingRule: Record<SessionBillingCountingRule, string> =
  {
    [SessionBillingCountingRule.AllSessions]: "All sessions",
    [SessionBillingCountingRule.CompleteOnly]: "Complete sessions only",
  };

/**
 * Gets the target date for the first pay period
 * */
export const getFirstPayPeriodDate = (data: FormDataType) => {
  const today = moment();
  const todayWeekDay = today.isoWeekday();
  const weekDayNumber = weekDaysNumbers[data.payPeriodStart];
  const isFirstDateFuture = data.firstPayPeriod === "next";
  let targetDate = today;

  if (todayWeekDay <= weekDayNumber && isFirstDateFuture) {
    targetDate = moment().isoWeekday(weekDayNumber);
  } else {
    if (isFirstDateFuture) {
      targetDate = moment().add(1, "weeks").isoWeekday(weekDayNumber);
    } else {
      targetDate = moment().subtract(1, "weeks").isoWeekday(weekDayNumber);
    }
  }

  return targetDate.toDate();
};

/**
 * Creates the member form data from rate types when it has the
 * overwriting property
 * */
export const getMemberOverwritingRateTypes = (
  accountId: string,
  data: ExtendedRateType[]
) => {
  const formattedData = data.map((item) => ({
    ...item,
    value: item?.overwriting?.[accountId]?.value ?? item.value,
  }));

  return formattedData;
};

/**
 * Formats a number in a currency format
 * */
export const formatValueCurrency = (
  value: number,
  currency: string,
  minimumFractionDigits: number = 0
) =>
  value.toLocaleString("en-US", {
    style: "currency",
    currency: isEmpty(currency) ? "USD" : currency,
    minimumFractionDigits,
  });

/**
 * Format an array of cycles to be used in the select box
 * */
export const getCyclesAsSelectOptions = (
  cycles: CycleType[]
): OptionItemType[] =>
  orderBy(cycles, "start", "desc").map((cycle) => ({
    value: `cycle-${kebabCase(cycle.label)}`,
    label: cycle.label,
    start: cycle.start,
    end: cycle.end,
  }));
