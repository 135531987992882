import { FC, ReactNode, useEffect, useRef, useState } from "react";
import classNames from "classnames";

import CaretIcon from "@components/Icons/CaretIcon";
import Transition from "@components/Transition";

interface DropDownProps {
  content: ReactNode;
  children: ReactNode;
  buttonClasses?: string;
  menuClasses?: string;
  caret?: boolean;
  disabled?: boolean;
}

const Dropdown: FC<DropDownProps> = ({
  content,
  children,
  buttonClasses,
  menuClasses,
  caret = false,
  disabled = false,
}) => {
  const locationDropdownRef = useRef();
  const [showOptions, setShowOptions] = useState(false);

  useEffect(() => {
    document.addEventListener("mouseup", toggleLocationDropdownVisibility);

    return () => {
      document.removeEventListener("mouseup", toggleLocationDropdownVisibility);
    };
  }, []);

  const toggleLocationDropdownVisibility = (e) => {
    const ignoreClickElement = document.querySelector(".ignore-click");
    if (
      !locationDropdownRef.current?.contains(e.target) &&
      !e.target.className?.includes?.("ignore-click") &&
      !ignoreClickElement?.contains(e.target)
    ) {
      setShowOptions(false);
    }
  };

  return (
    <div className="relative">
      <button
        type="button"
        ref={locationDropdownRef}
        onClick={() => setShowOptions(!showOptions)}
        className={classNames(
          "border border-color-grey-900 w-full rounded-lg flex items-center focus:outline-none focus:ring-0",
          disabled
            ? "opacity-50 cursor-not-allowed"
            : "hover:bg-grey-950 cursor-pointer",
          buttonClasses
        )}
      >
        {content}
        {caret && <CaretIcon className="inline mr-4 ml-2" />}
      </button>
      <Transition
        show={!disabled ? showOptions : false}
        className={classNames(
          "absolute -mt-3 right-3 bg-grey-950 shadow-lg z-10 rounded-2xl w-max overflow-hidden",
          menuClasses
        )}
        id="communication-item-list"
      >
        {children}
      </Transition>
    </div>
  );
};

export default Dropdown;
