import { FC } from "react";
import classNames from "classnames";

import PlusIcon from "@components/Icons/PlusIcon";

interface AddTodoButtonProps {
  createTodo: () => void;
  isPrivateTodos?: boolean;
  messaging?: boolean;
}

const AddTodoButton: FC<AddTodoButtonProps> = ({
  createTodo,
  isPrivateTodos,
  messaging,
}) => {
  const color = messaging ? "messaging" : isPrivateTodos ? "grey" : "action";
  const actionStyles =
    "bg-action-300/10 hover:bg-action-300/20 active:bg-action-300/30 text-action-300";
  const styleByColor = {
    grey: "bg-grey-950 hover:bg-grey-900 active:bg-grey-800 text-grey-500",
    action: actionStyles,
    messaging: `${actionStyles} dark:bg-grey-500/20 dark:hover:bg-grey-500/30 dark:text-white`,
  };

  return (
    <div className="w-full p-4">
      <button
        className={classNames(
          "rounded-lg text-left font-medium w-full p-4 focus:outline-none focus:ring-0 flex items-center gap-2",
          styleByColor[color]
        )}
        onClick={createTodo}
        data-heap-event-name={
          isPrivateTodos ? "new_private_todo_added" : "new_shared_todo_added"
        }
      >
        <PlusIcon /> New to-do
      </button>
    </div>
  );
};

export default AddTodoButton;
