import { SessionBillingCyclesApi } from "@practice/sdk";
import useSWR from "swr";

import { useAuth } from "@contexts/auth";
import { useBillingRatesContext } from "@contexts/billing-rates";
import { useRequestIdGenerator } from "@hooks/use-request-id-generator";
import { useSDKApi } from "@hooks/use-sdk-api";

const useSessionBillingCyclesAPI = () => {
  const { oid } = useAuth();
  const { hasSessionBillingSettings } = useBillingRatesContext();
  const generateRequestId = useRequestIdGenerator("useSessionBillingCyclesAPI");
  const sessionBillingCyclesApi = useSDKApi(SessionBillingCyclesApi);

  const swrData = useSWR(
    `organizations/${oid}/session-billing/cycles`,
    async () => {
      if (!oid || !hasSessionBillingSettings) return;
      return await sessionBillingCyclesApi.getSessionBillingCycles({
        organizationId: oid,
        xRequestId: generateRequestId(),
      });
    },
    { dedupingInterval: 600000 }
  );

  return {
    cycles: swrData.data ?? [],
    isCyclesFetching: swrData.isLoading,
  };
};

export default useSessionBillingCyclesAPI;
