import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const FilterIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      fill="none"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4 6.5C4 6.22386 4.22386 6 4.5 6H19.5C19.7761 6 20 6.22386 20 6.5V7.5C20 7.77614 19.7761 8 19.5 8H4.5C4.22386 8 4 7.77614 4 7.5V6.5Z" />
      <path d="M7 11.5C7 11.2239 7.22386 11 7.5 11H16.5C16.7761 11 17 11.2239 17 11.5V12.5C17 12.7761 16.7761 13 16.5 13H7.5C7.22386 13 7 12.7761 7 12.5V11.5Z" />
      <path d="M10 16.5C10 16.2239 10.2239 16 10.5 16H13.5C13.7761 16 14 16.2239 14 16.5V17.5C14 17.7761 13.7761 18 13.5 18H10.5C10.2239 18 10 17.7761 10 17.5V16.5Z" />
    </svg>
  );
};

export default FilterIcon;
