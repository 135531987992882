import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => (
  <svg
    className={classNames("fill-current", className)}
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none">
      <path
        d="M5.5 20.5a1 1 0 0 1-1-1v-15a1 1 0 0 1 1-1h6.38a1 1 0 0 1 .9.55l.72 1.45h5a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-5.39a1 1 0 0 1-.89-.55l-.72-1.45h-5v6a1 1 0 0 1-1 1zm1-15v6h6l1 2h4v-6h-5l-1-2h-5z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default Icon;
