import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const DisableIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M9.333 6.667h13.334c.238 0 .47.031.69.09l-16.6 16.6a2.67 2.67 0 01-.09-.69V9.333a2.667 2.667 0 012.666-2.666zm-.69 18.576c.22.059.451.09.69.09h13.334a2.667 2.667 0 002.666-2.666V9.333c0-.239-.031-.47-.09-.69l-16.6 16.6zM4 9.333A5.333 5.333 0 019.333 4h13.334A5.333 5.333 0 0128 9.333v13.334A5.333 5.333 0 0122.667 28H9.333A5.333 5.333 0 014 22.667V9.333z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default DisableIcon;
