import { FC, useState } from "react";
import classNames from "classnames";
import { TodoType } from "types/db/todos";

import { useAuth } from "@contexts/auth";
import useTodos from "@hooks/use-todos";
import { displayNameFromContact } from "@lib/contacts";
import { AccountType } from "@lib/data/schemas/account";
import { ClientType } from "@lib/data/schemas/client";
import { filterTodos } from "@lib/utils/todos";

import ClientAvatar from "@components/Client/ClientAvatar";
import CollapsedHeader from "@components/DisplayDetails/CollapsedHeader";

import Todos from "../Todos";
import TodoTags from "../TodoTags";

export interface ClientTodoSectionProps {
  client: ClientType;
  selectedSort?: string;
  assigneeId?: string;
  clientAssignee?: AccountType;
}

const ClientTodoSection: FC<ClientTodoSectionProps> = ({
  client,
  selectedSort,
  assigneeId,
  clientAssignee,
}) => {
  const { oid } = useAuth();
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const { incompleteTodos, completeTodos, createTodo, loading } = useTodos({
    coachUserId: oid!,
    contactId: client.id,
  });

  const assigneeOptions = [client, clientAssignee].filter(Boolean);

  if (loading)
    // animated loading state
    return (
      <div className="px-4 mt-6 animate-pulse flex items-center gap-2">
        <div className="h-8 w-8 bg-grey-950 rounded-full"></div>
        <div className="h-4 bg-grey-950 rounded-full w-[300px]"></div>
      </div>
    );

  const handleFilter = (todos: TodoType[]) =>
    filterTodos(todos, assigneeId, selectedSort);
  const filteredIncompleteTodos = handleFilter(incompleteTodos);
  const filteredCompleteTodos = handleFilter(completeTodos);

  if (!filteredIncompleteTodos?.length && !filteredCompleteTodos?.length)
    return null;

  return (
    <div className={classNames("mt-6", !collapsed && "mb-6")}>
      <div className="px-4">
        <CollapsedHeader
          collapsed={collapsed}
          toggleCollapsed={setCollapsed}
          leftElement={
            <div className="flex items-center">
              <ClientAvatar client={client} size="smaller" />
              <div className="text-lg ml-2 text-black">
                {displayNameFromContact(client)}
              </div>
              <TodoTags
                tagColor="grey"
                customListTodos={filteredIncompleteTodos || []}
              />
            </div>
          }
          className="text-grey-500"
        />
      </div>
      {!collapsed && (
        <>
          <Todos
            client={client}
            completeTodos={filteredCompleteTodos}
            incompleteTodos={filteredIncompleteTodos}
            createTodo={() => createTodo(assigneeId)}
            isPrivateTodos
            assigneeOptions={assigneeOptions as (ClientType | AccountType)[]}
          />
        </>
      )}
    </div>
  );
};

export default ClientTodoSection;
