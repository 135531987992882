import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 4H8V6H9V11H7C5.89543 11 5 11.8954 5 13V14H11V19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19V14H19V13C19 11.8954 18.1046 11 17 11H15V6H16V4Z"
        fill="#040506"
      />
    </svg>
  );
};

export default Icon;
