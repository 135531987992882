import { TodoType } from "types/db/todos";

import { useListConfiguration } from "@hooks/use-list-configuration";
import { todoFilters } from "@lib/utils/todos";

import CustomTodoFilter from "@components/Todos/CustomTodoFilter";

import useMemberFilterOptions from "./use-member-filter-options";

export default function useTodosFilter(
  todos?: TodoType[],
  filterType?: string,
  hideVisibility?: boolean
): {
  SortDropdown: React.ReactNode;
  filtersState: string;
  selectedSort: string;
  filteredTodos: TodoType[];
  incompleteTodos: TodoType[];
  completeTodos: TodoType[];
} {
  const { options: memberFilterOptions } = useMemberFilterOptions();

  const { SortDropdown, filtersMap, selectedSort, data } =
    useListConfiguration<TodoType>(
      todos,
      todoFilters,
      [
        ...(memberFilterOptions && !hideVisibility
          ? [
              {
                text: "Visibility",
                transform: (items: TodoType[], value: string | boolean) => {
                  return items.filter(
                    (item) =>
                      !value ||
                      value === "all" ||
                      item?.assigneeId === value ||
                      item.title === ""
                  );
                },

                options: memberFilterOptions,
                key: "visibility",
              },
            ]
          : []),
      ],
      `todoListConfiguration`,
      "Display options",
      filterType,
      CustomTodoFilter
    );

  const incompleteTodos =
    data?.filter((t: TodoType) => t.status === "incomplete") ?? [];
  const completeTodos =
    data?.filter((t: TodoType) => t.status === "complete") ?? [];

  return {
    SortDropdown,
    filtersState: filtersMap["visibility"],
    selectedSort,
    filteredTodos: data ?? [],
    incompleteTodos,
    completeTodos,
  };
}
