import axios from "axios";
import { nanoid } from "nanoid";
import useSWR, { useSWRConfig } from "swr";
import { TodoType } from "types/db/todos";

import analytics from "@lib/analytics";
import fetchJSON from "@lib/fetch-json";

export default function useTodos({
  coachUserId,
  contactId,
  limitedTo,
}: {
  coachUserId: string;
  contactId?: string;
  limitedTo?: string;
}) {
  let todosApiUrl = coachUserId && `/api/v1/users/${coachUserId}/todos`;
  if (contactId) {
    todosApiUrl += `?clientId=${contactId}`;
  }

  const { mutate } = useSWRConfig();
  const { data, error } = useSWR<{
    incomplete: TodoType[];
    complete: TodoType[];
  }>(todosApiUrl, fetchJSON, {
    dedupingInterval: 300000,
  });

  const isPrivate = !contactId;
  const track = (eventAction: string) => {
    analytics.track(isPrivate ? "private_todo_" : "shared_todo_" + eventAction);
  };

  const createTodo = (assigneeId?: string) => {
    const now = { _seconds: new Date().getTime() / 1000 };
    const newTodo = {
      id: nanoid(),
      title: "",
      status: "incomplete",
      updatedAt: now,
      createdAt: now,
      ...(assigneeId && { assigneeId }),
    };
    mutate(
      todosApiUrl,
      async (data) => ({
        ...data,
        taskIds: [...data?.taskIds, newTodo?.id],
        incomplete: [...data?.incomplete, newTodo],
      }),
      false
    );
    axios.post(todosApiUrl, newTodo);
    mutate(`/api/v1/users/${coachUserId}/todos/clients`);
    track("created");
  };

  const allTodos = data && [...data.incomplete, ...data.complete];

  const limitedTodos = allTodos?.filter(
    (todo: TodoType) => todo.assigneeId === limitedTo
  );

  return {
    todos: limitedTo ? limitedTodos : allTodos,
    incompleteTodos: data?.incomplete,
    completeTodos: data?.complete,
    error,
    createTodo,
    loading: !data && !error,
    limitedTodos,
  };
}
