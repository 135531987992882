import axios from "axios";
import { produce } from "immer";
import { Moment } from "moment";
import { useSWRConfig } from "swr";
import { useDocument } from "swr-firebase";
import { TodoType } from "types/db/todos";

import analytics from "@lib/analytics";
import { filteredTaskIds, filteredTodos } from "@lib/utils/todos";

type UpdateTodoType = (props: {
  title?: string;
  status?: "incomplete" | "complete";
  assigneeId?: string;
  dueAt?: Moment;
  markedBy?: string;
}) => void;

export default function useTodo({
  coachUserId,
  contactId,
  todoId,
}: {
  coachUserId: string;
  contactId?: string;
  todoId: string;
}) {
  let todoApiUrl =
    coachUserId && `/api/v1/users/${coachUserId}/todos/${todoId}`;
  let todosApiUrl = coachUserId && `/api/v1/users/${coachUserId}/todos`;
  if (contactId) {
    todoApiUrl += `?clientId=${contactId}`;
    todosApiUrl += `?clientId=${contactId}`;
  }

  const { mutate } = useSWRConfig();
  const { data: todo, error } = useDocument<TodoType>(
    coachUserId && contactId
      ? `users/${coachUserId}/clients/${contactId}/tasks/${todoId}`
      : null,
    {
      listen: true,
    }
  );

  const isPrivate = !contactId;
  const track = (eventAction: string) => {
    analytics.track({
      event: isPrivate ? "private_todo_" : "shared_todo_" + eventAction,
      properties: {
        isTemplate: todo?.isTemplate,
      },
    });
  };

  const updateTodo: UpdateTodoType = ({
    title,
    status,
    assigneeId,
    dueAt,
    markedBy,
  }) => {
    if (!title && title !== "" && !status && !assigneeId && !dueAt) return;

    const update = (t: TodoType) => {
      if (t.id === todoId) {
        if (assigneeId) t.assigneeId = assigneeId;
        if (dueAt) t.dueAt = dueAt.toDate();
        if (title) t.title = title;
      }
      return t;
    };

    const mutateFunction = status
      ? (data) => {
          const oldTodo = [...data?.incomplete, ...data?.complete].find(
            (todo) => todo?.id === todoId
          );
          let { taskIds, incomplete, complete } = data;
          if (status === "complete") {
            taskIds = filteredTaskIds({
              taskIds,
              todoId,
            });
            incomplete = filteredTodos({
              todos: incomplete,
              todoId,
            });
            complete = [
              ...complete,
              { ...oldTodo, ...(markedBy && { markedBy }), status },
            ];
          } else {
            taskIds = [...taskIds, todoId];
            complete = filteredTodos({
              todos: complete,
              todoId,
            });
            incomplete = [...incomplete, { ...oldTodo, status }];
          }
          return {
            ...data,
            taskIds,
            incomplete,
            complete,
          };
        }
      : produce((data) => {
          ({
            ...data,
            incomplete: data?.incomplete.map(update),
            complete: data?.complete.map(update),
          });
        });
    mutate(todosApiUrl, mutateFunction, false);
    axios
      .patch(todoApiUrl, {
        title,
        status,
        assigneeId,
        dueAt,
        markedBy,
      })
      .then(() => {
        mutate(`/api/v1/users/${coachUserId}/todos/clients`);
        mutate(todosApiUrl);
      });

    track(title ? "edited" : (status as string)); //edited, complete, or incomplete
  };

  const deleteTodo = () => {
    mutate(
      todosApiUrl,
      async (data) => ({
        ...data,
        taskIds: filteredTaskIds({ taskIds: data?.taskIds, todoId }),
        incomplete: filteredTodos({ todos: data?.incomplete, todoId }),
        complete: filteredTodos({ todos: data?.complete, todoId }),
      }),
      false
    );
    axios.delete(todoApiUrl);
    mutate(`/api/v1/users/${coachUserId}/todos/clients`);
    track("deleted");
  };

  return {
    todo,
    error,
    loading: !todo && !error,
    updateTodo,
    deleteTodo,
  };
}
