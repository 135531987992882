import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const HouseIcon: FC<SVGIconProps> = ({ className }) => (
  <svg
    className={classNames("fill-current", className)}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 10.7009V19H9V17C9 15.3431 10.3431 14 12 14C13.6569 14 15 15.3431 15 17V19H18V10.7009L12 6.45091L6 10.7009ZM12 4L4 9.66667V21H11V17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17V21H20V9.66667L12 4Z"
    />
  </svg>
);

export default HouseIcon;
