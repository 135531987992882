export const addHttp = (url: string) => {
  if (!url) return "";
  if (!url.includes("http")) {
    return `//${url}`;
  }
  return url;
};

export const linkify = (text: string) => {
  const linkRegex = /(https?\:\/\/)?(www\.)?[^\s]+\.[^\s]+/g;
  return text.replace(linkRegex, (url) => {
    return (
      '<a href="' +
      addHttp(url) +
      '" class="font-medium hover:underline" target="_blank">' +
      url +
      "</a>"
    );
  });
};

export const cleanLink = (text: string) => text.replace(/<\/?[^>]+(>|$)/g, "");
