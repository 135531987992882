import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 6C14 7.10456 13.1046 7.99999 12 8C12 8 12 8 12 8C10.8954 8 10 7.10457 10 6C10 4.89543 10.8954 4 12 4C13.1046 4 14 4.89543 14 6ZM5.33002 12.9441C3.43984 12.6254 2 10.9808 2 9C2 6.79086 3.79086 5 6 5C6.73894 5 7.43108 5.20037 8.02506 5.54975C8.24878 3.55257 9.94308 2 12 2C14.0569 2 15.7512 3.55257 15.9749 5.54975C16.5689 5.20037 17.2611 5 18 5C20.2091 5 22 6.79086 22 9C22 10.9808 20.5602 12.6254 18.67 12.9441C19.4321 14.0678 20 15.4099 20 16.9231C20 18.6857 19.2005 20.1487 17.9377 21.0317C17.116 21.638 16.0988 22 15 22C13.8663 22 12.8304 21.6186 12 20.9846C11.1696 21.6186 10.1337 22 9 22C7.90118 22 6.88393 21.638 6.0623 21.0317C4.79949 20.1487 4 18.6857 4 16.9231C4 15.4099 4.56789 14.0678 5.33002 12.9441ZM20 9C20 10.1046 19.1046 11 18 11C16.8954 11 16 10.1046 16 9C16 7.89543 16.8954 7 18 7C19.1046 7 20 7.89543 20 9ZM6 11C7.10457 11 8 10.1046 8 9C8 7.89543 7.10457 7 6 7C4.89543 7 4 7.89543 4 9C4 10.1046 4.89543 11 6 11ZM9 20C8.33768 20 7.7255 19.7799 7.2292 19.4071C6.50322 18.9091 6 18.0596 6 16.9231C6 13.0769 11.625 10 12 10C12.375 10 18 13.0769 18 16.9231C18 18.0596 17.4968 18.9092 16.7708 19.4071C16.2745 19.7799 15.6623 20 15 20C14.3789 20 13.8018 19.8064 13.3231 19.4748C13.0919 19.3147 12.8837 19.1224 12.7045 18.9042C12.6444 18.831 12.5876 18.7549 12.5342 18.6762C12.4483 18.5492 12.3714 18.4153 12.3045 18.2754C12.1931 18.0966 12.091 17.9023 12 17.6923C11.909 17.9023 11.8069 18.0966 11.6955 18.2754C11.6286 18.4153 11.5517 18.5492 11.4658 18.6762C11.4124 18.755 11.3556 18.8311 11.2955 18.9042C11.1163 19.1224 10.9081 19.3147 10.6769 19.4748C10.1982 19.8064 9.62114 20 9 20Z"
        fill="#040506"
      />
    </svg>
  );
};

export default Icon;
