import { useController } from "react-hook-form";
import classNames from "classnames";

import { useCheckScreenSize } from "@hooks/use-window-size";

import Dropdown from "@components/Dropdown";
import TextFieldForm from "@components/Form/TextFieldForm";
import CheckIcon from "@components/Icons/CheckIcon";
import ClockIcon from "@components/Icons/ClockIcon";

import Input from "./Input";

interface DurationFieldProps {
  variant?: "smartAction";
  name?: string;
  className?: string;
}

const DurationField: React.FC<DurationFieldProps> = ({
  variant,
  name,
  className,
}) => {
  const {
    field: { value, onChange },
  } = useController({
    name: name ?? "duration",
    rules: {
      required: true,
      validate: (value: string) => {
        if (Number.isNaN(parseInt(value))) return "Must be a number";
        if (parseInt(value) <= 0) return "Duration must be a positive number";
        return;
      },
    },
  });

  const isSmartAction = variant === "smartAction";
  const { isMD } = useCheckScreenSize();

  const options = [
    { value: "15", text: "15 minutes" },
    { value: "30", text: "30 minutes" },
    { value: "45", text: "45 minutes" },
    { value: "60", text: "1 hour" },
  ];

  return (
    <div className="relative">
      <Dropdown
        menuClasses={classNames("bottom-10", isMD ? "left-0" : "-right-10")}
        buttonClasses="!border-transparent"
        content={
          <Input
            Icon={isMD && <ClockIcon />}
            placeholder="Duration"
            value={value ? `${value} ${isMD ? "minutes" : ""}` : undefined}
            className={className}
          />
        }
      >
        <div
          className={classNames(
            "bg-white flex flex-col",
            isSmartAction ? "w-full" : "w-56"
          )}
        >
          <div className="text-sm text-grey-500 px-4 pt-4">Duration</div>
          <div className="py-2">
            {options.map((option) => (
              <button
                onClick={() => onChange(option.value)}
                className="py-2 text-sm text-left items-center px-4 flex hover:bg-action-950 w-full"
                key={option.value}
              >
                {option.text}
                {value === option.value && (
                  <CheckIcon className="ml-auto h-4 w-4" />
                )}
              </button>
            ))}
          </div>
          <TextFieldForm
            inputClassName="ignore-click text-sm hover:!border-grey-900"
            containerClassName="p-4 bg-grey-950 !mb-0"
            helper="minutes"
            placeholder="Custom"
            name="duration"
            required
            onInputChange={(e) => onChange(e.target.value)}
            type="number"
            defaultValue={value}
            otherInputProps={{
              onInput: (e) => {
                e.target.value = parseInt(e.target.value);
              },
            }}
          />
        </div>
      </Dropdown>
    </div>
  );
};

export default DurationField;
