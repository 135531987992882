import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 8C20 10.7614 17.7614 13 15 13C12.2386 13 10 10.7614 10 8C10 5.23858 12.2386 3 15 3C17.7614 3 20 5.23858 20 8ZM18 8C18 9.65685 16.6569 11 15 11C13.3431 11 12 9.65685 12 8C12 6.34315 13.3431 5 15 5C16.6569 5 18 6.34315 18 8Z"
      />
      <path d="M11 16C9.89543 16 9 16.8954 9 18V19.5C9 19.7761 8.77614 20 8.5 20H7.5C7.22386 20 7 19.7761 7 19.5V18C7 15.7909 8.79086 14 11 14H19C21.2091 14 23 15.7909 23 18V19.5C23 19.7761 22.7761 20 22.5 20H21.5C21.2239 20 21 19.7761 21 19.5V18C21 16.8954 20.1046 16 19 16H11Z" />
      <path d="M9.85355 11.6542C10.0488 11.8495 10.0488 12.1661 9.85355 12.3613L6.61091 15.604C6.41565 15.7992 6.09907 15.7992 5.90381 15.604L5.1967 14.8969C5.00144 14.7016 5.00144 14.385 5.1967 14.1898L6.37868 13.0078L6.38818 13.0004L6.37132 11.0004L5.1967 9.8258C5.00144 9.63053 5.00144 9.31395 5.1967 9.11869L5.90381 8.41158C6.09907 8.21632 6.41565 8.21632 6.61091 8.41158L9.85355 11.6542Z" />
      <rect x="2" y="11" width="5" height="2" rx="0.5" />
    </svg>
  );
};

export default Icon;
