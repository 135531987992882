import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const DocGraphIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.99997 14H7.99997V18H9.99997V14Z" />
      <path d="M13 15V18H11V15H13Z" />
      <path d="M16 18V11H14V18H16Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 3C4 2.44772 4.44772 2 5 2H16C18.2091 2 20 3.79086 20 6V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V3ZM6 20V4H16C17.1046 4 18 4.89543 18 6V20H6Z"
      />
    </svg>
  );
};

export default DocGraphIcon;
