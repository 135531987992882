import { Dispatch, FC, SetStateAction } from "react";
import classNames from "classnames";

import ViewOffIcon from "@components/Icons/ViewOffIcon";
import ViewOnIcon from "@components/Icons/ViewOnIcon";

interface CompletedTodosButtonProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isPrivateTodos?: boolean;
  messaging?: boolean;
}

const CompletedTodosButton: FC<CompletedTodosButtonProps> = ({
  isOpen,
  setIsOpen,
  isPrivateTodos,
  messaging,
}) => {
  const color = messaging ? "messaging" : isPrivateTodos ? "grey" : "action";
  const actionStyles =
    "bg-action-300/5 hover:bg-action-300/10 active:bg-action-300/20 text-action-300";
  const styleByColor = {
    grey: "bg-grey-950 hover:bg-grey-900 active:bg-grey-800 text-grey-500",
    action: actionStyles,
    messaging: `${actionStyles} dark:bg-grey-500/20 dark:hover:bg-grey-500/30 dark:text-white`,
  };

  return (
    <button
      className={classNames(
        "flex items-center rounded-lg text-sm font-medium text-left px-2 py-1.5 m-4 gap-1",
        styleByColor[color]
      )}
      onClick={() => setIsOpen(!isOpen)}
      data-heap-event-name={
        isPrivateTodos
          ? isOpen
            ? "completed_private_todos_hidden"
            : "completed_private_todos_shown"
          : isOpen
          ? "completed_shared_todos_hidden"
          : "completed_shared_todos_shown"
      }
    >
      {isOpen ? "Hide" : "Show"} completed items
      {isOpen ? <ViewOffIcon /> : <ViewOnIcon />}
    </button>
  );
};

export default CompletedTodosButton;
