import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const ScaleIcon: FC<SVGIconProps> = ({
  className,
  width = 24,
  height = 24,
  ...rest
}) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect x={3} y={17} width={2} height={4} />
      <rect x={7} y={14} width={2} height={7} />
      <rect x={11} y={11} width={2} height={10} />
      <rect x={15} y={7} width={2} height={14} />
      <rect x={19} y={3} width={2} height={18} />
    </svg>
  );
};

export default ScaleIcon;
