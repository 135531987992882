import { FC, useCallback, useState } from "react";
import { TodoType } from "types/db/todos";

import { usePageVisibility } from "@hooks/use-page-visibility";
import { useCheckScreenSize } from "@hooks/use-window-size";
import { AccountType } from "@lib/data/schemas/account";
import { ClientType } from "@lib/data/schemas/client";

import TodoListItem from "@components/Todos/TodoListItem";

interface TodoListPops {
  todos: TodoType[];
  contact?: ClientType;
  channel: any;
  messaging?: boolean;
  isPrivateTodos?: boolean;
  createTodo: () => void;
  assigneeOptions: (AccountType | ClientType)[];
}

const TodoList: FC<TodoListPops> = ({
  todos,
  contact,
  channel,
  messaging,
  isPrivateTodos,
  createTodo,
  assigneeOptions,
}) => {
  // this prevent cross-tabs conflicts with autofocus and blur event handlers
  const [canAutofocus, setCanAutofocus] = useState(
    document?.visibilityState === "visible"
  );

  const { isLG, isMD } = useCheckScreenSize();
  const isSmallScreen = !(isLG && isMD);
  const onPageVisible = useCallback(() => setCanAutofocus(true), []);
  const onPageHidden = useCallback(() => setCanAutofocus(false), []);
  usePageVisibility(onPageVisible, onPageHidden);

  const isNewTodo = (todo?: TodoType) => todo?.title === "";

  if (!todos?.length) return null;

  return (
    <>
      {todos?.map((todo) => (
        <TodoListItem
          key={todo?.id}
          contact={contact}
          todo={todo}
          createTodo={createTodo}
          channel={channel}
          canAutofocus={isSmallScreen ? false : canAutofocus && isNewTodo(todo)}
          messaging={messaging}
          isPrivateTodos={isPrivateTodos}
          assigneeOptions={assigneeOptions}
        />
      ))}
    </>
  );
};

export default TodoList;
