import React, { FC, ReactNode } from "react";

const InputHelper: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div className="flex -mr-px mt-1">
      <span className="flex items-center leading-normal bg-grey-lighter rounded rounded-l-none border border-l-0 border-grey-light px-3 whitespace-nowrap text-grey-dark text-sm">
        {children}
      </span>
    </div>
  );
};

export default InputHelper;
