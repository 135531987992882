import { FC, ReactNode } from "react";
import classNames from "classnames";

import Modal from "@components/Modals/Modal";

export interface SideModalProps {
  trigger?: ReactNode;
  show?: boolean;
  toggleShow?: (value: boolean) => void;
  children?: ReactNode;
  className?: string;
}

const SideModal: FC<SideModalProps> = ({
  trigger,
  show,
  toggleShow,
  children,
  className,
}) => {
  if (!trigger && !show) return null;
  return (
    <Modal
      className={classNames("py-6 overflow-y-auto relative", className)}
      trigger={trigger}
      show={show}
      toggleShow={toggleShow}
      size="side"
    >
      {children}
    </Modal>
  );
};

export default SideModal;
