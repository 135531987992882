import { ReactNode } from "react";
import classNames from "classnames";

const Input: React.FC<{
  Icon?: ReactNode;
  value?: string;
  placeholder: string;
  onClick?: () => void;
  className?: string;
}> = ({ Icon, value, placeholder, onClick, className }) => {
  return (
    <div
      className={classNames(
        "flex items-center border text-sm border-grey-900 px-2 py-1 rounded-lg w-fit h-8",
        value ? "text-black-ink" : "text-grey-500",
        onClick && "cursor-pointer",
        className
      )}
      onClick={onClick}
    >
      {!!value && Icon && <span className="mr-1">{Icon}</span>}
      <span className="truncate">{value || placeholder}</span>
    </div>
  );
};

export default Input;
