import { FC } from "react";
import { compact, sortBy } from "lodash";

import { useBillingRatesContext } from "@contexts/billing-rates";
import useMemberFilterOptions from "@hooks/use-member-filter-options";
import { displayNameFromContact } from "@lib/contacts";

import FilterDropdown from "@components/FilterDropdown";
import Selectbox, { SelectboxOptionType } from "@components/Form/Selecbox";

import { useGetBillableRequestsContext } from "./BillableRequestsContext";

interface BillableRequestFilterDropdownProps {
  hideMemberFilter?: boolean;
}

const BillableRequestFilterDropdown: FC<BillableRequestFilterDropdownProps> = ({
  hideMemberFilter,
}) => {
  const {
    filters,
    setFilters,
    mode,
    clients: responseClients,
  } = useGetBillableRequestsContext();
  const isReviewer = mode === "reviewer";
  const clients = responseClients?.clients ?? [];

  // member
  const { options: unformattedMembers } = useMemberFilterOptions();
  const memberOptions = compact(
    unformattedMembers?.map(({ value, text }) => {
      if (value === "all")
        return {
          label: "All members",
          value: "all-members",
        };
      return {
        label: text,
        value,
      };
    })
  );

  // billable request type
  const { billingRates } = useBillingRatesContext();
  const billableTypes = billingRates?.filter(
    (billingRate) => billingRate.type === "billable"
  );
  const billableTypeOptions = [
    { label: "All types", value: "all-billable-types" },
    ...billableTypes?.map(({ id, title }) => ({ label: title, value: id })),
  ];

  // client
  const sortedClientOptions = sortBy(clients, "label");
  const clientOptions = [
    { label: "All clients", value: "all-clients" },
    ...sortedClientOptions?.map((client) => ({
      label: displayNameFromContact(client),
      value: client.id,
    })),
  ];

  const handleChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    key: string,
    allValue: string
  ) => {
    const val = e.target.value;
    setFilters({
      ...filters,
      [key]: val === allValue ? undefined : val,
    });
  };

  const renderSelect = (
    title: string,
    value: string,
    options: SelectboxOptionType[],
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
  ) => (
    <div className="flex items-center justify-between p-2">
      <p className="text-black-ink">{title}</p>
      <Selectbox
        className="max-w-[160px] truncate"
        value={value}
        options={options}
        onChange={onChange}
      />
    </div>
  );

  const renderContent = (
    <div className="p-1">
      {isReviewer &&
        !hideMemberFilter &&
        renderSelect(
          "Members",
          filters.accountId ?? "all-members",
          memberOptions,
          (e) => {
            handleChange(e, "accountId", "all-members");
          }
        )}
      {renderSelect(
        "Type",
        filters.billingRateId ?? "all-billable-types",
        billableTypeOptions,
        (e) => {
          handleChange(e, "billingRateId", "all-billable-types");
        }
      )}
      {!isReviewer &&
        renderSelect(
          "Client",
          filters.clientId ?? "all-clients",
          clientOptions as SelectboxOptionType[],
          (e) => {
            handleChange(e, "clientId", "all-clients");
          }
        )}
    </div>
  );

  return (
    <FilterDropdown
      options={[]}
      footer={renderContent}
      optionClassNames="divide-none"
    />
  );
};

export default BillableRequestFilterDropdown;
