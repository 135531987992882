import { FC, useEffect, useRef, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import classNames from "classnames";
import { Moment } from "moment";
import { TodoType } from "types/db/todos";

import { useAuth } from "@contexts/auth";
import useTodo from "@hooks/use-todo";
import { AccountType } from "@lib/data/schemas/account";
import { ClientType } from "@lib/data/schemas/client";
import { cleanLink, linkify } from "@lib/utils/links";
import { sortTodoAssignees } from "@lib/utils/todos";

import IconButton from "@components/Buttons/IconButton";
import FilledCheckbox from "@components/Form/FilledCheckbox";
import TrashIcon from "@components/Icons/TrashIcon";
import AssignTodoPicker from "@components/Todos/AssignTodoPicker";
import TodoDueDatePicker from "@components/Todos/TodoDueDatePicker";

import TodoDone from "../TodoDone";

export const todoTextColor = {
  grey: "text-black-ink placeholder-grey-500",
  action: "text-action-300 placeholder-action-300/50",
  messaging:
    "text-action-300 placeholder-action-300/50 dark:text-white dark:placeholder:text-white/50",
};

const getMessageObject = (
  type: string,
  todo: TodoType & { type?: string }
) => ({
  text: todo.title,
  system_message_type: type,
  data: { task_id: todo.id, type: todo?.type || "todo" },
  silent: true,
});

export interface TodoListItemProps {
  todo: TodoType;
  contact?: ClientType;
  createTodo: () => void;
  canAutofocus: boolean;
  channel?: any;
  messaging?: boolean;
  isPrivateTodos?: boolean;
  assigneeOptions: (AccountType | ClientType)[];
}

const TodoListItem: FC<TodoListItemProps> = ({
  contact,
  todo,
  createTodo,
  canAutofocus,
  channel,
  messaging,
  isPrivateTodos,
  assigneeOptions: unsortedAssigneeOptions,
}) => {
  const { isOwner, oid, aid } = useAuth();
  const ref = useRef<HTMLLIElement>(null);

  const [focused, setFocused] = useState<boolean>(canAutofocus);
  const [interimLinkFocus, setInterimLinkFocus] = useState<boolean>(false);
  const [title, setTitle] = useState<string>(todo.title);

  const handleDualFocus = (val: boolean) => {
    setFocused(val);
    setInterimLinkFocus(val);
  };

  const assigneeOptions = sortTodoAssignees(unsortedAssigneeOptions);
  const showAssignees = assigneeOptions?.length && assigneeOptions.length > 1;

  const { assigneeId, dueAt, id, status } = todo;
  const isComplete = status === "complete";

  const contactId = contact?.id;
  const isClient = oid === contact?.accountId;
  const coachUserId = (isClient ? contact?.coachUserId : oid) as string;

  const { updateTodo, deleteTodo } = useTodo({
    coachUserId,
    contactId,
    todoId: id,
  });

  const userIsAssigned = isClient
    ? assigneeId === contactId
    : !assigneeId
    ? isOwner
    : assigneeId === aid;

  const color = messaging ? "messaging" : isPrivateTodos ? "grey" : "action";

  const handleBlur = (e: any) => {
    const isContained = ref?.current && ref.current.contains(e?.relatedTarget);
    const currentTitle = e.target.value;
    const isNew = todo.title === "";
    if (!currentTitle) {
      // delete if focusing on an element outside this item boundaries
      if (isNew && !isContained) {
        deleteTodo();
      }
    } else {
      setFocused(!!isContained);
      if (isNew) {
        channel?.sendMessage(
          getMessageObject("task_added", { ...todo, title: currentTitle })
        );
      }
    }
  };

  const handleCheckboxChange: (checked: boolean | "indeterminate") => void = (
    checked
  ) => {
    setFocused(false);
    if (checked === true) {
      updateTodo({
        status: "complete",
        markedBy: isClient ? oid : aid,
      });
      channel?.sendMessage(getMessageObject("task_completed", todo));
    } else if (checked === false) {
      updateTodo({
        status: "incomplete",
      });
    }
  };

  const handleAssignId = (id: string) => {
    const assigneeId = id === todo?.assigneeId ? "delete" : id;
    updateTodo({ assigneeId, ...(title && { title }) });
  };

  const handleDueDate = (dueAt: Moment) => {
    updateTodo({ dueAt, ...(title && { title }) });
  };

  const handleDelete = () => {
    deleteTodo();
    if (todo.title)
      channel?.sendMessage(getMessageObject("task_deleted", todo));
  };

  const handleUpdate = () => {
    if (title || todo.title) {
      cleanLink(title) !== todo.title &&
        updateTodo({
          title: cleanLink(title),
        });
      handleDualFocus(false);
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleUpdate();
      if (e.target.value) createTodo();
    }
  };

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      const clickedOnInterimLink = e.target?.id === `${todo.id}-interim-link`;
      const clickedOnTextarea = e.target?.id === `${todo.id}-textarea`;
      if (
        ref?.current &&
        !ref.current.contains(e.target as Node) &&
        !clickedOnInterimLink
      ) {
        handleUpdate();
      } else {
        if (
          title !== linkify(title) &&
          !clickedOnInterimLink &&
          !clickedOnTextarea
        )
          setInterimLinkFocus(true);
        setFocused(true);
      }
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [title]);

  const textStyles = todoTextColor[color];

  const maxWidth = messaging
    ? "max-w-[275px] xl:max-w-[325px] 2xl:max-w-[375px]"
    : contactId && !isPrivateTodos
    ? "max-w-[200px] md:max-w-[160px] lg:max-w-[225px]"
    : "max-w-[300px]";

  const renderTitleWithLink = (onClick: () => void, id: string) => (
    <div
      onClick={onClick}
      id={id}
      className={classNames(textStyles, "font-medium")}
      dangerouslySetInnerHTML={{
        __html: linkify(title),
      }}
    />
  );

  return (
    <li
      ref={ref}
      className="w-full group block transition duration-150 ease-in-out outline-none px-1 mt-1"
    >
      <div
        tabIndex={0}
        className={classNames(
          "flex items-center relative p-3 rounded-lg border border-transparent",
          {
            grey: "hover:bg-grey-950",
            action: "hover:bg-action-300/5",
            messaging: "hover:bg-action-300/5 dark:hover:bg-grey-300/5",
          }[color],
          focused &&
            {
              grey: "!border-grey-900",
              action: "!border-action-300/10",
              messaging: "!border-action-300/10 dark:!border-white/30",
            }[color]
        )}
      >
        <div
          className={classNames(
            "transition duration-500 ease-in-out flex flex-1 items-center w-full"
          )}
        >
          <FilledCheckbox
            className="ml-0.5 mr-4"
            name="status"
            onChange={handleCheckboxChange}
            defaultChecked={isComplete}
            color={color}
          />
          <div className="flex flex-col items-start w-full gap-1">
            <div className="w-full text-sm">
              {isComplete ? (
                <div
                  className={classNames(
                    "font-medium cursor-pointer",
                    {
                      grey: "text-grey-500",
                      action: "text-action-300",
                      messaging: "text-action-300 dark:text-white",
                    }[color]
                  )}
                >
                  <p
                    className={classNames(
                      !focused && classNames("truncate", maxWidth),
                      messaging && "dark:text-white"
                    )}
                  >
                    {renderTitleWithLink(() => null, `${todo.id}`)}
                  </p>
                </div>
              ) : interimLinkFocus ? (
                renderTitleWithLink(
                  () => handleDualFocus(false),
                  `${todo.id}-interim-link`
                )
              ) : focused ? (
                <TextareaAutosize
                  id={`${todo.id}-textarea`}
                  className={classNames(
                    "resize-none leading-[21px] block w-full text-sm focus:outline-none bg-transparent p-0 border-0 focus:ring-0 font-medium overflow-y-hidden",
                    textStyles
                  )}
                  autoFocus={canAutofocus}
                  disabled={isComplete}
                  placeholder="Name your to-do"
                  onBlur={handleBlur}
                  onKeyDown={handleKeyDown}
                  defaultValue={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              ) : (
                <p
                  className={classNames(
                    classNames("font-medium truncate py-[1.5px]", maxWidth),
                    textStyles
                  )}
                >
                  {title}
                </p>
              )}
            </div>
            {(assigneeId || dueAt || focused) && (
              <div className="flex w-full items-center gap-1.5 h-5">
                {showAssignees && (
                  <AssignTodoPicker
                    todo={todo}
                    assigneeOptions={assigneeOptions}
                    handleAssignId={handleAssignId}
                    color={color}
                  />
                )}
                <TodoDueDatePicker
                  onDueDateChange={handleDueDate}
                  dueAt={dueAt}
                  color={color}
                  userIsAssigned={userIsAssigned}
                  isComplete={isComplete}
                  shared={!!contactId}
                />
              </div>
            )}
            {isComplete && focused && showAssignees && (
              <TodoDone
                color={color}
                messaging={messaging}
                membersAndContact={assigneeOptions}
                todo={todo}
              />
            )}
          </div>
        </div>
        <IconButton
          className={classNames(
            "self-end group-hover:block group-hover:visible",
            {
              grey: "text-grey-800",
              action: "text-action-300 opacity-50",
              messaging: "text-action-300 opacity-50 dark:text-peach-500",
            }[color],
            focused ? "visible sm:invisible" : "invisible "
          )}
          icon={<TrashIcon />}
          onClick={handleDelete}
          data-heap-event-name={
            isPrivateTodos ? "private_todo_deleted" : "shared_todo_deleted"
          }
        />
      </div>
    </li>
  );
};

export default TodoListItem;
