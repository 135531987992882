import React, { FC, useCallback, useState } from "react";
import classNames from "classnames";

import Spinner from "@components/LoadingSpinner";
import { useSelectableListContext } from "@components/SelectableList";
import { Tooltip } from "@components/Tooltips/Tooltip";
import Transition from "@components/Transition";

import { MultipleActionsProps, MultipleActionType } from "./types";

const MultipleActions: FC<MultipleActionsProps> = ({ label, actions }) => {
  const { selected, clearSelected } = useSelectableListContext();
  const [loading, setLoading] = useState(false);
  const show = !!selected.length;

  const onClickHandler = useCallback(
    (fn: (() => void) | undefined, clearSelection: boolean | undefined) =>
      async () => {
        {
          setLoading(true);
          await fn?.();
          if (clearSelection) clearSelected();
          setLoading(false);
        }
      },
    [clearSelected]
  );

  const renderAction = (action: MultipleActionType, index: number) => {
    const renderButton = (
      <button
        key={`action-button-${index}`}
        type="button"
        onClick={onClickHandler(action?.onClick, action.clearSelection)}
        className={classNames(
          "flex items-center justify-center bg-grey-150 hover:bg-grey-250 focus:outline-none w-12 h-12 rounded-lg right-0 mr-3 last:mr-0",
          action?.className
        )}
      >
        {action.icon}
      </button>
    );

    return action?.tooltip ? (
      <Tooltip
        key={`action-tooltip-${index}`}
        trigger={renderButton}
        contentClassName="px-3 py-1 !text-xs"
      >
        {action.tooltip}
      </Tooltip>
    ) : (
      renderButton
    );
  };

  // tslint:disable-next-line
  const renderLoading = loading && <Spinner className="rounded w-10 h-10" />;

  return (
    <Transition
      show={show}
      className="w-80 fixed z-50 right-4 sm:right-8 bottom-20 sm:bottom-24 bg-black-ink rounded p-3 pl-4 flex justify-between items-center"
    >
      <p className="text-white">{label}</p>
      <div className="flex gap-1">
        {!loading && actions.map(renderAction)}
        {renderLoading}
      </div>
    </Transition>
  );
};

export default MultipleActions;
