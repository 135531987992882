import classNames from "classnames";

import { Button } from "@components/Button";
import FilterIcon from "@components/Icons/FilterIcon";

export interface CustomTodoFilterProps {
  onClick: (value: boolean) => void;
  filtersMap?: any;
  filterType?: string;
}

const CustomTodoFilter: React.FC<CustomTodoFilterProps> = ({
  onClick,
  filtersMap,
  filterType,
}) => {
  const isMessaging = filterType === "messaging";
  const isShared = filterType === "shared" || isMessaging;
  const options = Object.values(filtersMap)
    .filter(Boolean)
    .filter((o) => o !== "all")?.length;

  return (
    <Button
      small
      className={classNames(
        isShared &&
          "bg-action-300/10 text-action-300 h-8 w-8 rounded-lg hover:bg-action-300/20 active:bg-action-300/20",
        isMessaging &&
          "dark:bg-grey-500/20 dark:text-white dark:hover:bg-grey-950/20"
      )}
      onClick={() => onClick(true)}
    >
      <div className="flex items-center gap-1 px-1">
        {!isShared && <div className="hidden sm:block">Filters</div>}
        {options ? (
          <div className="h-6 flex items-center justify-center">
            <div
              className={classNames(
                "rounded-full flex items-center justify-center text-xs h-5 w-5",
                isShared ? "bg-action-300 text-white" : "bg-action-700",
                isMessaging && "dark:bg-grey-100"
              )}
            >
              {options}
            </div>
          </div>
        ) : (
          <FilterIcon />
        )}
      </div>
    </Button>
  );
};

export default CustomTodoFilter;
