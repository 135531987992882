import React, { ReactNode, useMemo } from "react";
import classNames from "classnames";
import { kebabCase } from "lodash";
import { useRouter } from "next/router";

interface TabsProps {
  children: ReactNode;
  queryParamName?: string;
  tabClassNames?: string;
  inactiveTabClassNames?: string;
  right?: ReactNode;
  className?: string;
}

const Tabs: React.FC<TabsProps> & { Item: typeof Item } = ({
  children,
  className,
  queryParamName = "view",
  tabClassNames,
  inactiveTabClassNames,
  right,
}) => {
  const router = useRouter();
  const { query } = router;

  const items = useMemo(
    () =>
      React.Children.toArray(children).flatMap((item) =>
        React.isValidElement<ItemProps>(item)
          ? [{ ...item, _key: kebabCase(item.props.name) }]
          : []
      ),
    [children]
  );

  if (items.length === 0) return null;

  const activeTabKey = (
    (query[queryParamName] ?? items[0]._key) as string
  ).toLowerCase();
  const activeItem = items.find(
    (item) => item._key.toLowerCase() === activeTabKey
  );

  return (
    <>
      <nav className={classNames("flex items-center", className)}>
        <div className="flex-1">
          {items.map((item) => (
            <button
              className={classNames(
                "hover:bg-action-950 focus:bg-action-900 font-normal text-xl rounded leading-6 px-4 py-2 mr-2 text-black-ink bg-action-900",
                tabClassNames,
                activeTabKey !== item._key &&
                  classNames(
                    "text-grey-500 bg-transparent",
                    inactiveTabClassNames
                  )
              )}
              onClick={() =>
                router.replace({
                  query: {
                    ...query,
                    [queryParamName]: item._key,
                  },
                })
              }
              key={item._key}
            >
              {item.props.header || item.props.name}
            </button>
          ))}
        </div>
        {right}
      </nav>
      {activeItem}
    </>
  );
};

interface ItemProps {
  name: string;
  children?: ReactNode;
  header?: ReactNode;
}

export const Item: React.FC<ItemProps> = ({ children }) => {
  return <>{children}</>;
};

Tabs.Item = Item;

export default Tabs;
