import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const LockCloseIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 11H7V18H17V11ZM7 9C5.89543 9 5 9.89543 5 11V19.5C5 19.7761 5.22386 20 5.5 20H18.5C18.7761 20 19 19.7761 19 19.5V11C19 9.89543 18.1046 9 17 9H7Z"
      />
      <path d="M15 9V8C15 6.34315 13.6569 5 12 5C10.3431 5 9 6.34315 9 8V9H7V8C7 5.23858 9.23858 3 12 3C14.7614 3 17 5.23858 17 8V9H15Z" />
    </svg>
  );
};

export default LockCloseIcon;
