import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => (
  <svg
    className={classNames("fill-current", className)}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 6C2 4.34315 3.34315 3 5 3H19C20.6569 3 22 4.34315 22 6V18C22 19.6569 20.6569 21 19 21H5C3.34315 21 2 19.6569 2 18V6ZM5 5H19C19.5523 5 20 5.44771 20 6V12H16.8317C16.3341 12 15.9222 12.3685 15.8106 12.8534C15.5203 14.1144 14.9532 15 13.5 15H10.5C9.04682 15 8.47972 14.1144 8.18939 12.8534C8.07775 12.3685 7.66589 12 7.16831 12H4V6C4 5.44772 4.44772 5 5 5ZM4 14V18C4 18.5523 4.44772 19 5 19H19C19.5523 19 20 18.5523 20 18V14H17.5656C17.3665 14.5968 17.0568 15.234 16.5421 15.7763C15.7429 16.6184 14.6783 17 13.5 17H10.5C9.32167 17 8.25712 16.6184 7.45794 15.7763C6.9432 15.234 6.63345 14.5968 6.4344 14H4Z"
      fill="currentColor"
    />
  </svg>
);

export default Icon;
