import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.33333 2.66675C6.96514 2.66675 6.66667 2.96523 6.66667 3.33341V8.66675C6.66667 9.03494 6.96514 9.33341 7.33333 9.33341H8.66667C9.03486 9.33341 9.33333 9.03494 9.33333 8.66675V3.33341C9.33333 2.96522 9.03486 2.66675 8.66667 2.66675H7.33333Z"
        fill="#040506"
      />
      <path
        d="M4 10.0001V7.66675C4 7.48265 4.14924 7.33341 4.33333 7.33341H5C5.18409 7.33341 5.33333 7.48265 5.33333 7.66675V10.0001C5.33333 10.3683 5.63181 10.6667 6 10.6667H10C10.3682 10.6667 10.6667 10.3683 10.6667 10.0001V7.66675C10.6667 7.48265 10.8159 7.33341 11 7.33341H11.6667C11.8508 7.33341 12 7.48265 12 7.66675V10.0001C12 11.1047 11.1046 12.0001 10 12.0001H8.66667V13.3334C8.66667 13.7016 8.36819 14.0001 8 14.0001C7.63181 14.0001 7.33333 13.7016 7.33333 13.3334V12.0001H6C4.89543 12.0001 4 11.1047 4 10.0001Z"
        fill="#040506"
      />
    </svg>
  );
};

export default Icon;
