import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const DocInvoiceIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      fill="none"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.21 15.27C10.59 15.45 11.03 15.56 11.53 15.6V16.63H12.67V15.55C12.99 15.49 13.2733 15.3967 13.52 15.27C13.7733 15.1367 13.9833 14.9767 14.15 14.79C14.3233 14.6033 14.4533 14.39 14.54 14.15C14.6333 13.91 14.68 13.6533 14.68 13.38C14.68 12.8733 14.5267 12.45 14.22 12.11C13.9133 11.7633 13.44 11.53 12.8 11.41L12.57 11.37V9.70002C12.9967 9.79335 13.35 9.99335 13.63 10.3L14.49 9.34001C14.29 9.10668 14.04 8.91002 13.74 8.75002C13.4467 8.59001 13.09 8.48335 12.67 8.43002V7.39001H11.53V8.44001C10.8833 8.52668 10.3867 8.74668 10.04 9.10001C9.69332 9.45335 9.51999 9.91335 9.51999 10.48C9.51999 10.9933 9.67332 11.43 9.97999 11.79C10.2867 12.1433 10.76 12.3767 11.4 12.49L11.63 12.53V14.37C11.3567 14.33 11.0967 14.2467 10.85 14.12C10.61 13.9933 10.3833 13.8167 10.17 13.59L9.29999 14.54C9.53332 14.84 9.83665 15.0833 10.21 15.27ZM11.09 10.92C10.9833 10.8067 10.93 10.6433 10.93 10.43C10.93 10.2167 10.9833 10.0467 11.09 9.92001C11.2033 9.79335 11.3833 9.71002 11.63 9.67002V11.18C11.3833 11.1133 11.2033 11.0267 11.09 10.92ZM13.11 12.97C13.2233 13.0833 13.28 13.2533 13.28 13.48C13.28 13.7133 13.22 13.9067 13.1 14.06C12.9867 14.2067 12.81 14.3067 12.57 14.36V12.71C12.8167 12.77 12.9967 12.8567 13.11 12.97Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 2C4.44772 2 4 2.44772 4 3V21C4 21.5523 4.44772 22 5 22H19C19.5523 22 20 21.5523 20 21V6C20 3.79086 18.2091 2 16 2H5ZM6 4V20H18V6C18 4.89543 17.1046 4 16 4H6Z"
      />
    </svg>
  );
};

export default DocInvoiceIcon;
